import React, {useEffect} from 'react';
import {ContentType, TypeOption} from "./adDetails/ContentType";
import {ContentIllus} from "./adDetails/ContentIllus";
import ColorPicker from "./adDetails/ColorPicker";
import {illusAssets, mapIllusToColor} from "../assets/illustration";
import {ContentColor} from "../styles/colorCode";
import TitleNTagline from "./adDetails/TitleNTagline";
import OptionalInfo from "./adDetails/OptionalInfo";
import {ProgramType} from "../GlobalTypes";

interface ContentDetailsProps {
  programType: ProgramType,
  contentType: any,
  setType: (value: keyof typeof TypeOption) => void,
  contentIllus: any,
  setIllus: (value: keyof typeof illusAssets) => void,
  disableIllus: boolean,
  contentColor: any,
  setColor: (value: ContentColor) => void,
  disableColor: boolean,
  title: string,
  setTitle: (val: string) => void,
  tagline: string,
  setTagline: (val: string) => void,
  showAddress: boolean,
  setShowAddress: (val: boolean) => void,
  showHours: boolean,
  setShowHours: (val: boolean) => void,
  showContact: boolean,
  setShowContact: (val: boolean) => void,
  address: string,
  setAddress: (val: string) => void,
  hours: string,
  setHours: (val: string) => void,
  contactIcon: string,
  setContactIcon: (val: string) => void,
  contactInfo: string,
  setContactInfo: (val: string) => void,
}

const ContentDetails = (props: ContentDetailsProps) => {
  const {
    programType,
    contentType,
    contentIllus,
    disableIllus,
    contentColor,
    disableColor,
    title,
    tagline,
    setType,
    setIllus,
    setColor,
    setTitle,
    setTagline,
    showAddress,
    setShowAddress,
    showHours,
    setShowHours,
    showContact,
    setShowContact,
    address,
    setAddress,
    hours,
    setHours,
    contactIcon,
    setContactIcon,
    contactInfo,
    setContactInfo,
  } = props;

  useEffect(() => {
    if (programType === ProgramType.Basic) {
      setColor(mapIllusToColor[contentIllus]);
    }
  }, [contentIllus, setColor, programType]);

  return (
    <>
      <ContentType setType={setType} value={contentType} programType={programType} />
      <ContentIllus setIllus={setIllus} disabled={disableIllus} value={contentIllus}/>
      {programType !== ProgramType.Basic && <ColorPicker setColor={setColor} disabled={disableColor} value={contentColor}/>}
      <TitleNTagline title={title} setTitle={setTitle} tagline={tagline} setTagline={setTagline}/>
      <OptionalInfo address={address} contactIcon={contactIcon} contactInfo={contactInfo} hours={hours}
                    showAddress={showAddress}
                    showContact={showContact}
                    showHours={showHours}
                    setAddress={setAddress}
                    setContactIcon={setContactIcon}
                    setContactInfo={setContactInfo}
                    setHours={setHours}
                    setShowAddress={setShowAddress}
                    setShowHours={setShowHours}
                    setShowContact={setShowContact}/>
    </>
  )
};

export default ContentDetails;

