import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";
import React from "react";

interface ErrorDialogProps {
  open: boolean,
  setOpen: (val: boolean) => void,
}

const ErrorDialog = (props: ErrorDialogProps) => {
  const {open, setOpen} = props;
  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText>
          Apologies for the inconvenience.<br/>
          We're facing technical issues. Please try again later.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>OK</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorDialog;
