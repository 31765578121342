import React from 'react';

import MainHeader from "./components/MainHeader";
import MainFooter from "./components/MainFooter";

import './styles/linkNYC.css';
import {createTheme, ThemeProvider} from "@mui/material";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: ['Helvetica Neue', 'Nunito', 'Roboto', 'sans-serif'].join(','),
    },
  },
});

function App(props: { component: React.ReactElement<any, string | React.JSXElementConstructor<any>> }) {
  return (
    <ThemeProvider theme={theme}>
      <div className="app-container">
        <MainHeader/>
        {props.component}
        <MainFooter/>
      </div>
    </ThemeProvider>
  );
}

export default App;
