import React from 'react';
import Box from "@mui/material/Box";
import {Grid, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../../store";

const Locations = () => {
  const selectedMarkers = useSelector((state: RootState) => state.plusMap.selectedMarkers);
  const kioskAddresses = [...Object.keys(selectedMarkers).map((key) => [key, selectedMarkers[key as keyof typeof selectedMarkers]]), ...Array(10 - Object.keys(selectedMarkers).length).fill([])]
    .map((item, index) => {
      return (
        item[1] ?
          <Typography fontSize={20} fontWeight={"bold"} key={index}>
            {index + 1}.&nbsp;&nbsp;{item[1]['address']}.
          </Typography>
          :
          <Typography fontSize={20} fontWeight={"bold"} key={index}>
            {index + 1}.
          </Typography>
      )
    });

  return (
    <Box className="desc-container">
      <Grid item className="title-font">
        Locations
      </Grid>
      <Grid item>
        {kioskAddresses}
      </Grid>
    </Box>
  )
};

export default Locations;
