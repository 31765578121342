import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {
    BrowserRouter,
    Route,
    RouteObject,
    Routes,
} from 'react-router-dom';

import './index.css';
import store from './store/index';
import App from "./App";
import ErrorPage from "./components/ErrorPage";
import initialRoutes from "./initialRoutes";
import NewsPost from "./components/pressReleases/NewsPost";
import News from "./components/pressReleases/News";
import {WordPressPost} from "./components/pressReleases/interfaces";

const fetchNewRoutes = async (): Promise<RouteObject[]> => {
    const url: string = (process.env.REACT_APP_WORDPRESS_API as string);
    const response = await fetch(url, {method: "GET",});
    const posts = await response.json();
    const newRoutes = posts.map((post: WordPressPost) => {
        return {
            path: `/I1qRlWdvvBN2Zg5AkS74dhHHqQgI3iiULpVasUCmsLl4EAt7YZ/${post['ID']}`,
            element: <App component={<NewsPost post={post}/>}/>,
            errorElement: <App component={<ErrorPage/>}/>,
        }
    });
    newRoutes.push({
        path: '/I1qRlWdvvBN2Zg5AkS74dhHHqQgI3iiULpVasUCmsLl4EAt7YZ/news',
        element: <App component={<News posts={posts}/>}/>,
        errorElement: <App component={<ErrorPage/>}/>,
    });
    return newRoutes;
};

const DynamicRouter = () => {
    const [routes, setRoutes] = useState(initialRoutes);
    const effectTriggered = useRef(false); // Track if effect has been triggered

    useEffect(() => {
        if (!effectTriggered.current) {
            const loadRoutes = async () => {
                const newRoutes = await fetchNewRoutes();
                setRoutes((prevRoutes) => [...prevRoutes, ...newRoutes]);
            };
            loadRoutes();
            effectTriggered.current = true; // Mark effect as triggered to prevent re-running
        }
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                {routes.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={route.element}
                        errorElement={<App component={<ErrorPage/>}/>}
                    />
                ))}
            </Routes>
        </BrowserRouter>
    );
}

const Index = () => {
    return (
        <React.StrictMode>
            <Provider store={store}>
                <DynamicRouter />
            </Provider>
        </React.StrictMode>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<Index/>);
