import React from 'react';
import Box from "@mui/material/Box";
import {Grid, Typography} from "@mui/material";
import "../../styles/summary.css"
import {DateTime} from "luxon";

const DEFAULT_AD_PERIOD = 30;

interface StartDateProps {
  date: number,
}

const StartDate = (props: StartDateProps) => {
  const {date} = props;

  const startDate = DateTime.fromMillis(date).setZone('America/New_York');
  const endDate = startDate.plus({ days: DEFAULT_AD_PERIOD });

  return (
    <Box className="desc-container">
      <Grid item className="title-font">
        Ad Start Date
      </Grid>
      <Grid item>
        <Typography fontSize={20} fontWeight={"bold"}>
          {startDate.toFormat('DDD')} - {endDate.toFormat('DDD')}
        </Typography>
      </Grid>
    </Box>
  )
}

export default StartDate;
