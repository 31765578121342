import {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import {
  formatToISODate,
  getCurrentTimeInSeconds
} from "../util/date";
import {DateTime} from "luxon";


interface DateTextFieldProps {
  error: boolean,
  setError: (val: boolean) => void,
  date: number,
  storeDate: (timestamp: number) => void,
  label: string,
}

const DateTextField = (props: DateTextFieldProps) => {
  const {error, setError, date, storeDate, label} = props;

  const [startDate, setStartDate] = useState<number>(date);

  const storeStartTime = (date: string) => {
    const timestamp = DateTime.fromISO(date, {zone: 'America/New_York'}).toMillis();
    setStartDate(timestamp);
    storeDate(timestamp);
  }

  useEffect(() => {
    getCurrentTimeInSeconds()
      .then(timestamp => {
        const firstAvailableDay = DateTime
          .fromMillis(timestamp * 1000)
          .setZone('America/New_York')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .plus({ days: 7 });
        if (startDate >= firstAvailableDay.toMillis()) {
          setError(false);
        } else {
          setError(true);
        }
      })
      .catch(err => {
          console.log(err);
          setError(true)
        }
      );
  }, [startDate, setError])

  return (
    <TextField
      error={error}
      id="date"
      label={label}
      type="date"
      InputLabelProps={{shrink: true}}
      sx={{width: '100%', background: 'white'}}
      value={formatToISODate(startDate)}
      onChange={(e) => storeStartTime(e.target.value)}
      inputProps={{
        min: (() => {
          // The inputProps.min property expects a date formatted as 'YYYY-MM-DD'
          const currentDate = DateTime.now().setZone('America/New_York');
          const dateOneWeekFromNow = currentDate.plus({days: 7});
          return dateOneWeekFromNow.toISODate();
        })(),
      }}
    />
  );
}

export default DateTextField;
