import {createSlice} from "@reduxjs/toolkit";

export interface PlusSummaryState {
  preview: string
}

const initialState: PlusSummaryState = {
  preview: ''
}

const plusSummarySlice = createSlice({
  name: 'plusSummary',
  initialState,
  reducers: {
    storePreview(state, action) {
      state.preview = action.payload;
    }
  }
});

export const plusSummaryActions = plusSummarySlice.actions;

export default plusSummarySlice;
