import React, {useState} from "react";
import '../styles/summary.css';
import {Button, CircularProgress} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import {formatToISODate} from "../util/date";
import {DateTime} from "luxon";
import {sendPostRequest} from "../util/request";
import {useNavigate} from "react-router-dom";
import ErrorDialog from "./ErrorDialog";
import {ProgramType} from "../GlobalTypes";

interface SubmitRequestBodyInterface {
  programType: string,
  customerName: string,
  customerEmail: string,
  campaignName: string,
  price: number,
  startDate: string,
  location: string,
  base64Image: string,
}

interface PlusContractProps {
  setShowContract: (val: boolean) => void
}

const PlusContract = (props: PlusContractProps) => {
  const {setShowContract} = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

  const savedPreview = useSelector((state: RootState) => state.plusSummary.preview);
  const savedPrice = useSelector((state: RootState) => state.plusMap.price);
  const selectedMarkers = useSelector((state: RootState) => state.plusMap.selectedMarkers);
  const kioskAddresses = (
    <ol>
      {[...Object.keys(selectedMarkers).map((key) => [key, selectedMarkers[key as keyof typeof selectedMarkers]]), ...Array(10 - Object.keys(selectedMarkers).length).fill([])]
        .map((item, index) => {
          return (
            item[1] ?
              <li key={index} style={{height: "25px"}}>
                {item[1]['address']}
              </li>
              : null
          )
        })
      }
    </ol>
  );

  const savedContent = useSelector((state: RootState) => state.plusContent);
  const savedStartTime = useSelector((state: RootState) => state.plusMap.startDate);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);

    const submitUrl: string = (process.env.REACT_APP_LAMBDA_URL as string) + '/email';
    const locations: { [key: string]: string } = {};
    for (const key in selectedMarkers) {
      if (selectedMarkers.hasOwnProperty(key)) {
        const marker = selectedMarkers[key];
        locations[marker.siteId] = marker.address;
      }
    }

    const body: SubmitRequestBodyInterface = {
      programType: ProgramType[ProgramType.Plus],
      customerName: savedContent.name,
      customerEmail: savedContent.email,
      campaignName: savedContent.title,
      price: savedPrice,
      startDate: formatToISODate(savedStartTime),
      location: JSON.stringify(locations),
      base64Image: savedPreview,
    }
    sendPostRequest(submitUrl, body)
      .then(() => {
        setLoading(false);
        navigate("/submitted");
      })
      .catch(err => {
        setLoading(false);
        setShowErrorDialog(true);
        console.log(err);
      })
  };

  const StartDate = (
    <ul>
      <li>{DateTime.fromMillis(savedStartTime).setZone('America/New_York').toLocaleString(DateTime.DATE_SHORT)}</li>
    </ul>
  );

  const DeclineButton = (
    <Button
      variant="contained"
      component="label"
      disabled={loading}
      sx={{
        paddingX: 5,
        paddingY: 1,
        textTransform: 'none',
        fontWeight: 'bold',
        color: 'white',
        fontSize: 'large',
        backgroundColor: '#afafaf',
        marginRight: 4
      }}
      onClick={() => setShowContract(false)}
    >
      Decline
    </Button>
  );

  const AgreeButton = (
    <Button
      variant="contained"
      component="label"
      disabled={loading}
      sx={{
        paddingX: 6,
        paddingY: 1,
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: 'large'
      }}
      onClick={handleSubmit}
    >
      Agree
    </Button>
  );
  const ProgressIndicator = (
    <CircularProgress
      size={50}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px',
      }}
    />
  );

  const terms = (
    <div className="contract-terms">
      <ol>
        <li><p>The identity of the “Company” (or “us,” “our,” or “we) depends on where the content (“Content”) is displayed. If you (“you” or “Content Provider”) are providing Content for i) the LinkNYC, Link5G or New York City public payphone network (each a “Link”), CityBridge, LLC is the Company and ii) on any other Link network, Intersection Media, LLC is the Company.</p></li>
        <li><p>Company may display Content (including any required logos or trademarks) on a Link on a space available basis and at the sole discretion of the Company.</p></li>
        <li><p>The form of payment due depends on the kind of advertising obtained.  If the Content Provider is receiving advertising on the LinkNYC network via LinkLocal, no monetary or non-monetary compensation in the form of materials, services or other benefits, tangible or intangible, or in the nature of barter are due to, or will be due to Company nor to any of its affiliates.  If the Content Provider is receiving advertising on the LinkNYC network via LinkLocal Plus or any other advertising, full payment is due and required to be made in advance of display of the Content and You will be billed in advance.  If applicable, You must make all good faith objections with respect to such billed amounts within fifteen (15) days of payment.</p></li>
        <li><p>Content Provider will indemnify, defend and save harmless the Company, its affiliates, and each respective municipality or transit authority (the “Owner”) where the Links are located; and all of their employees, officers, agents and representatives, against any liability to which they may be subjected by reason of the Content displayed under this Contract, including, but not limited to, liability for infringement of trademarks, trade names, copyrights, invasion of rights of privacy, defamation, illegal competition or trade practices, violation of laws as well as all reasonable costs, including attorney’s fees and expenses, in defending or settling any such action or actions.</p></li>
        <li><p>Content must be in compliance with the advertising standards set by the Owner and are subject to our approval and each Owner’s approval. In the event an Owner disapproves of the Content, adverse publicity results from the presence of the Content, or we have a good faith reason to expect either, we may remove the Content.  Our and each Owner’s decision as to acceptability shall be final and without any liability whatsoever.  At a minimum, and without limitation, nudity, pornographic, profane or obscene creative is prohibited.</p></li>
        <li><p>You may not assign this Contract.</p></li>
        <li><p>This Contract  becomes effective when accepted by us and contains the full agreement of the parties, and no prior representation or assurance, verbal or written not contained herein, affects or alters the obligation of either party.</p></li>
        <li><p>You grant us permission to use photographs or renderings of the Content in our marketing materials.  You agree that any third-party advertising, tickers, links or bots embedded in the Content are also subject to the regulations and indemnity provisions set forth in this agreement. We may install our identification imprints on all displays as long as such imprints.</p></li>
        <li><p>In the event of legal action arising out of this Contract for any city within the Link network, this Contract and all claims arising hereunder are governed by the laws of the State of New York and the parties submit to the exclusive jurisdiction of New York, New York.</p></li>
        <li><p>This contract is non‑cancellable unless otherwise noted.</p></li>
      </ol>
      <p className={"contract-terms-accept"}>By clicking the "Accept" or “I Agree” button, or by providing Content and allowing us to display it on Link, you agree to be bound by the terms and conditions of this Contract. If You do not agree to the terms of this Contract, you should not use this platform.</p>
    </div>
  );

  return (
    <div className="contract-container">
      <div className={"contract-title"}>LinkLocal Plus Terms and Conditions</div>
      <div className={"contract-body"}>
        <div className={"contract-subtitle"}>Link Locations</div>
        {kioskAddresses}
        <div className={"contract-subtitle"}>Campaign Start Date</div>
        {StartDate}
        {terms}
      </div>
      <div className={"contract-buttons"}>
        {DeclineButton}
        {AgreeButton}
        {loading && ProgressIndicator}
      </div>
      <ErrorDialog open={showErrorDialog} setOpen={setShowErrorDialog} />
    </div>
  );
};

export default PlusContract;
