import {useEffect, useState, useMemo} from "react";
import {MapContainer, TileLayer, Marker} from "react-leaflet";
import "leaflet/dist/leaflet.css"
import "../styles/map.css"
import * as L from "leaflet";
import LoadingBar from "./LoadingBar";
import LinkLocalPageTitle from "./LinkLocalPageTitle";
import {
  Box,
  Button,
  Grid,
  Typography,
  Tooltip,
  DialogContent,
  DialogContentText,
  DialogActions, Dialog
} from "@mui/material";
import TotalCost from "./TotalCost";
import DateTextField from "./DateTextField";
import {useDispatch, useSelector} from "react-redux";
import {plusMapActions, PlusMapState} from "../store/plus-map-slice";
import {useNavigate} from "react-router-dom";
import {RootState} from "../store";

type MapMarker = {
  siteId: string,
  address: string,
  lat: number,
  lng: number
}

export type MapMarkers = {
  [key: string]: MapMarker
}

const LinkMap = () => {
  const center = useMemo<L.LatLngTuple>(() => ([40.752821639211106, -74.00119843132416]), []);
  const savedState: PlusMapState = useSelector((state: RootState) => state.plusMap);
  const savedStartDate = useSelector((state: RootState) => state.plusMap.startDate);

  const [markers, setMarkers] = useState<MapMarkers | {}>({});
  const [selectedMarkers, setSelectedMarkers] = useState<MapMarkers | {}>(savedState.selectedMarkers);
  const [price, setPrice] = useState<number>(savedState.price);
  const [mapError, setMapError] = useState<boolean>(false);
  const [dateError, setDateError] = useState<boolean>(false);
  const [showDateErrorDialog, setShowDateErrorDialog] = useState<boolean>(false);

  const dispatch = useDispatch();
  const storeUserMapData = () => {
    dispatch(plusMapActions.storeMapData({
      selectedMarkers: selectedMarkers,
      price: price
    }))
  }
  const storeStartDate = (timestamp: number) => {
    dispatch(plusMapActions.storeStartDate({
      startDate: timestamp
    }));
  }

  const navigate = useNavigate();
  const redirectToSummary = () => {
    if (dateError) {
      setShowDateErrorDialog(true);
      return;
    }
    storeUserMapData();
    navigate("/plus-summary");
  }
  const redirectToCreator = () => {
    storeUserMapData();
    navigate("/plus-content-creator");
  }

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + '/map/LinkNYC.kml', {
      headers: {"Content-Type": "application/xml; charset=utf-8"},
      cache: "no-store"
    })
      .then(res => res.text())
      .then(data => {
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(data, "text/xml");
          const itemList = xmlDoc.querySelectorAll("Placemark")

          itemList.forEach(item => {
              const extendedData = item.querySelectorAll("SimpleData")
              // If the kiosk ad is not active, then don't add it to the map
              if (extendedData[1].textContent === 'False') {
                return;
              }
              const siteId = extendedData[0].textContent
              const crossing = item.querySelector("name")?.textContent
              const address = item.querySelector("address")?.textContent
              const coordinates = item.querySelector("coordinates")?.textContent?.split(",")
              const lat = coordinates?.[1]
              const lng = coordinates?.[0]
              // if lat and lng are None, then don't add site to the map
              if (lat === 'None' && lng === 'None') return
              setMarkers((prev) => {
                return {
                  ...prev,
                  [siteId as string]: {
                    crossing: crossing,
                    siteId: siteId,
                    address: address,
                    lat: Number(lat),
                    lng: Number(lng)
                  }
                }
              })
            }
          )
        }
      ).catch(() => {
      setMapError(true)
    })
  }, [])

  const LeafIcon = L.Icon.extend({
    options: {
      iconSize: [25, 25],
      iconAnchor: [10, 10],
      popupAnchor: [0, -25],
    }
  });

  // @ts-ignore
  const blueIcon = new LeafIcon({
    iconUrl: "../blue.svg"
  })
  // @ts-ignore
  const grayIcon = new LeafIcon({
    iconUrl: "../gray.svg"
  });

  const getMarkerIcon = (key: string) => {
    if (selectedMarkers[key as keyof typeof selectedMarkers])
      return blueIcon;
    return grayIcon;
  }

  const selectDeselectMarker = (key: string | number, marker: MapMarker) => {
    if (Object.keys(selectedMarkers).length < 10 && !selectedMarkers[key as keyof typeof selectedMarkers]) {
      setSelectedMarkers((prev: MapMarkers | {}) => {
        return {
          ...prev,
          [key]: marker
        }
      })
    } else if (selectedMarkers[key as keyof typeof selectedMarkers]) {
      setSelectedMarkers((prev: MapMarkers | {}) => {
        const newMarkers = {...prev}
        delete newMarkers[key]
        return newMarkers
      })
    } else {
      alert("You can only select up to 10 kiosks")
    }
  }

  const BackButton = (
    <Tooltip title="Back" arrow>
      <Button variant="contained" component="label"
              sx={{
                paddingX: 4,
                paddingY: 2,
                textTransform: 'none',
                fontWeight: 'bold',
                color: 'white',
                fontSize: 'medium',
                backgroundColor: '#afafaf'
              }}
              onClick={redirectToCreator}
      >
        Back
      </Button>
    </Tooltip>
  );

  const ContinueButton = (
    <Tooltip title="Continue" arrow>
      <Button variant="contained" component="label"
              sx={{
                paddingX: 8,
                paddingY: 2,
                textTransform: 'none',
                fontWeight: 'bold',
                fontSize: 'medium',
              }}
              onClick={redirectToSummary}
      >
        Continue
      </Button>
    </Tooltip>
  );

  const DateErrorDialog = (
      <Dialog open={showDateErrorDialog}>
        <DialogContent>
          <DialogContentText>
            The date you entered is not valid.<br/>
            Please try again.<br/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDateErrorDialog(false)}>OK</Button>
        </DialogActions>
      </Dialog>
  );

  return (
    <div className={"page-container"}>
      <Box sx={{width: 1000, margin: "5px"}}>
        <LoadingBar step={2} totalSteps={3}/>
        <LinkLocalPageTitle title={"Select Your Kiosk"}/>

        {mapError ?
          <Typography variant='h6' color='error'>Error loading map. Please try again later.</Typography>
          :
          <Grid container spacing={2} columns={12}>
            <Grid item xs={12} md={8}>
              <MapContainer
                center={center}
                transform3DLimit={100}
                zoom={16}
                scrollWheelZoom={true}
                minZoom={10}
                maxZoom={18}
              >
                <TileLayer
                  url={'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoianVsaWV5YW5nIiwiYSI6ImNraGRwdGszMjBmOGgycXFyNzV5dDRlY3MifQ.Kyk5UQ4cRu4YoLDObiW4LA'}
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  id="paulserraino.n0dn3pbe"
                />
                {/*Render Markers on Map*/}
                {Object.keys(markers).map((key: string, index) => {
                  const marker = markers[key as keyof typeof markers]
                  return (
                    <Marker
                      title={key}
                      key={index}
                      position={[marker['lat'], marker['lng']]}
                      icon={getMarkerIcon(key)}
                      eventHandlers={{
                        click: () => {
                          selectDeselectMarker(key, marker)
                        }
                      }}
                    />
                  )
                })}
              </MapContainer>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container direction='column' spacing={2}>
                <Grid item>
                  <Box className='kiosks-container'>
                    <Typography pt={2} pl={2} fontWeight='bold' className="map-title-color">
                      Selected Kiosks:
                    </Typography>
                    <ol>

                      {[...Object.keys(selectedMarkers).map((key) => [key, selectedMarkers[key as keyof typeof selectedMarkers]]), ...Array(10 - Object.keys(selectedMarkers).length).fill([])]
                        .map((item, index) => {
                          return (
                            item[1] ?
                              <li key={index} style={{height: "25px"}}>
                                <Typography
                                  color='#2576d2'
                                  height={25}
                                  onClick={() => selectDeselectMarker(item[0], item[1])}
                                  title={"Delete Kiosk"}
                                  style={{cursor: 'pointer'}}>
                                  {item[1]['address']}
                                </Typography>
                              </li>
                              :
                              <li key={index} style={{color: 'gray', height: '25px'}}>
                                <Typography height={25}/>
                              </li>
                          )
                        })}
                    </ol>
                  </Box>
                </Grid>
                <Grid item className={'kiosks-container'} ml={2} mb={2} pr={2} mt={2}>
                  <Grid item className="map-title-color">
                    Desired Start Date
                  </Grid>
                  <Grid item>
                    <DateTextField
                      error={dateError}
                      setError={setDateError}
                      date={savedStartDate}
                      storeDate={storeStartDate}
                      label={""}
                    />
                  </Grid>
                  {DateErrorDialog}
                </Grid>
                <Grid item className={'kiosks-container'} ml={2} pr={2}>
                  <TotalCost totalKiosk={Object.keys(selectedMarkers).length} price={price} setPrice={setPrice}/>
                </Grid>
                <Grid item style={{display: 'flex', justifyContent: "space-between"}}>
                  <Grid>
                    {BackButton}
                  </Grid>
                  <Grid>
                    {ContinueButton}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      </Box>
    </div>
  )
};

export default LinkMap;
