import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import '../styles/spinner.css';

interface SpinnerProps{
  spinnerText: string
  loadingError?: boolean
  loadingErrorMessage?: string
  closeSpinner?: () => void
}

export const Spinner = ({spinnerText, loadingError = false, loadingErrorMessage="Something went wrong, please try agin!", closeSpinner}: SpinnerProps ) => (
  <div className='spinner-wrapper'>
    <div className='spinner-text'>{spinnerText}</div>
    {loadingError ?
      <Box>
        {loadingErrorMessage}
        <Box display="flex"
             justifyContent="center"
             alignItems="center">
          <Button sx={{margin: "20px"}} variant="contained" component="label" onClick={closeSpinner}>
            OK
          </Button>
        </Box>
      </Box>
      :
      <Box>
        <CircularProgress />
      </Box>
    }
  </div>
  );
