import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {basicContentActions, BasicContentState} from "../store/basic-content-slice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store";
import {TypeOption} from "./adDetails/ContentType";
import {illusAssets} from "../assets/illustration";
import {ContentColor} from "../styles/colorCode";
import Box from "@mui/material/Box";
import LoadingBar from "./LoadingBar";
import Grid from "@mui/material/Grid";
import UserInfo from "./UserInfo";
import ContentDetails from "./ContentDetails";
import Preview from "./Preview";
import {Button, TextField} from "@mui/material";
import {ProgramType} from "../GlobalTypes";
import {useNavigate} from "react-router-dom";
import DateTextField from "./DateTextField";

const Item = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const BasicContentCreator = () => {
  const savedState: BasicContentState = useSelector((state: RootState) => state.basicContent);

  const [userName, setUserName] = useState<string>(savedState.name);
  const [userEmail, setUserEmail] = useState<string>(savedState.email);
  const [zipCode, setZipCode] = useState<string>(savedState.zipCode);
  const [contentType, setContentType] = useState<keyof typeof TypeOption>(savedState.type);
  const [contentIllus, setContentIllus] = useState<keyof typeof illusAssets>(savedState.illus);
  const [contentColor, setContentColor] = useState<ContentColor>(savedState.color);
  const [contentTitle, setContentTitle] = useState<string>(savedState.title);
  const [contentTagline, setContentTagline] = useState<string>(savedState.tagline);
  const [contentAddress, setContentAddress] = useState<string>(savedState.address);
  const [contentHours, setContentHours] = useState<string>(savedState.hours);
  const [contactInfo, setContactInfo] = useState<string>(savedState.contactInfo);
  const [contactIcon, setContactIcon] = useState<string>(savedState.contactIcon)
  const [showAddress, setShowAddress] = useState<boolean>(savedState.showAddress);
  const [showHours, setShowHours] = useState<boolean>(savedState.showHours);
  const [showContact, setShowContact] = useState<boolean>(savedState.showContact);

  const [dateError, setDateError] = useState<boolean>(false);

  const dispatch = useDispatch();
  const storeContents = () => {
    dispatch(basicContentActions.storeContents({
      name: userName,
      email: userEmail,
      zipCode: zipCode,
      type: contentType,
      illus: contentIllus,
      color: contentColor,
      title: contentTitle,
      tagline: contentTagline,
      address: contentAddress,
      hours: contentHours,
      contactInfo: contactInfo,
      contactIcon: contactIcon,
      showAddress: showAddress,
      showHours: showHours,
      showContact: showContact,
    }));
  }
  const storeStartDate = (timestamp: number) => {
    dispatch(basicContentActions.storeStartDate({
      startDate: timestamp
    }));
  }

  const navigate = useNavigate();
  const redirectToSummary = () => {
    storeContents();
    navigate("/basic-summary");
  }

  const zipCodeField = (
    <TextField
      margin={"dense"}
      label="Business / Event Zip Code"
      variant="filled"
      fullWidth
      value={zipCode}
      onChange={(e) => setZipCode(e.target.value)}
    />);

  return (
    <div className={"page-container"}>
      <Box sx={{width: 1000, margin: "5px"}}>
        <LoadingBar step={1} totalSteps={2}/>
        <Grid container spacing={2} columns={12}>
          <Grid item xs={7}>
            <Item>
              <UserInfo userName={userName} setUserName={setUserName} userEmail={userEmail}
                        setUserEmail={setUserEmail}/>
              {zipCodeField}
            </Item>
            <Item>
              <ContentDetails
                programType={ProgramType.Basic}
                contentType={contentType}
                contentIllus={contentIllus}
                disableIllus={false}
                contentColor={contentColor}
                disableColor={false}
                title={contentTitle}
                tagline={contentTagline}
                setType={setContentType}
                setIllus={setContentIllus}
                setColor={setContentColor}
                setTitle={setContentTitle}
                setTagline={setContentTagline}
                showAddress={showAddress}
                setShowAddress={setShowAddress}
                showHours={showHours}
                setShowHours={setShowHours}
                showContact={showContact}
                setShowContact={setShowContact}
                address={contentAddress}
                setAddress={setContentAddress}
                hours={contentHours}
                setHours={setContentHours}
                contactIcon={contactIcon}
                setContactIcon={setContactIcon}
                contactInfo={contactInfo}
                setContactInfo={setContactInfo}/>
            </Item>
            <Item>
              <DateTextField
                error={dateError}
                setError={setDateError}
                date={savedState.startDate}
                storeDate={storeStartDate}
                label={"Desired Start Date"}
              />
            </Item>
          </Grid>
          <Grid item xs={5}>
            <Item>
              <Preview
                type={contentType}
                illus={contentIllus}
                color={contentColor}
                title={contentTitle}
                tagline={contentTagline}
                address={contentAddress}
                showAddress={showAddress}
                hours={contentHours}
                showHours={showHours}
                contactInfo={contactInfo}
                showContact={showContact}
                contactIcon={contactIcon}
                userLogo={null}
                showUserLogo={false}
                ref={null}
              />
            </Item>
            <div className={"continue-button"}>
              <Button variant="contained"
                      component="label"
                      size="large"
                      sx={{
                        paddingX: 10,
                        paddingY: 1,
                        textTransform: "none",
                        fontSize: "large"
                      }}
                      onClick={redirectToSummary}
              >
                Continue
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default BasicContentCreator;
