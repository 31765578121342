import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store";
import {sendPostRequest} from "../util/request";
import {useNavigate} from "react-router-dom";
import {ImageProperty, setUpImage} from "../util/image";
import {Box, Button, CircularProgress, Grid} from "@mui/material";
import Preview from "./Preview";
import StartDate from "./summary/StartDate";
import LoadingBar from "./LoadingBar";
import LinkLocalPageTitle from "./LinkLocalPageTitle";
import ErrorDialog from "./ErrorDialog";
import {basicContentActions, BasicContentState} from "../store/basic-content-slice";
import BasicContract from "./BasicContract";

const BasicSummary = () => {
  const contentState: BasicContentState = useSelector((state: RootState) => state.basicContent);

  const [loading, setLoading] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [showContract, setShowContract] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setLoading(true);

    // store preview in redux
    const defaultFileName = "";
    createImageMetaData(defaultFileName)
      .then(body => {
        // The image should contain all the meta data
        // so that we could upload it using the internal content creator.
        // Our lambda '/download' adds those data and returns the encoded base64 image
        const downloadUrl: string = (process.env.REACT_APP_LAMBDA_URL as string) + '/download';
        return sendPostRequest(downloadUrl, body);
      })
      .then(res => {
        // the returned image looks like "b`/9j/4AAQSkZJRgABAQAA...`".
        // cut out first 2 chars(b`) and the last (`)
        const parsedBase64Image = res.filename.substring(2).slice(0, -1);
        dispatch(basicContentActions.storePreview(parsedBase64Image));
      })
      .then(() => {
        setLoading(false);
        setShowContract(!showContract);
      })
      .catch(err => {
        setLoading(false);
        setShowErrorDialog(true);
        console.log(err);
      });
  }

  const navigate = useNavigate();
  const redirectToPrevious = () => {
    navigate("/basic-content-creator");
  }

  const printRef = useRef<HTMLDivElement>(null);
  const createImageMetaData = async (imgName: string) => {
    const properties: ImageProperty = {
      userLogo: null,
      color: contentState.color,
      type: contentState.type,
      illus: contentState.illus,
      title: contentState.title,
      tagline: contentState.tagline,
      address: contentState.address,
      showAddress: contentState.showAddress,
      hours: contentState.hours,
      showHours: contentState.showHours,
      contactInfo: contentState.contactInfo,
      showContact: contentState.showContact,
      contactIcon: contentState.contactIcon,
      showUserLogo: false,
      cloudLogoId: null,
    }
    return setUpImage(imgName, printRef, properties);
  }

  const BackButton = (
    <Button
      variant="contained"
      component="label"
      disabled={loading}
      sx={{
        paddingX: 6,
        paddingY: 2,
        textTransform: 'none',
        fontWeight: 'bold',
        color: 'white',
        fontSize: 'medium',
        backgroundColor: '#afafaf',
      }}
      onClick={redirectToPrevious}
    >
      Back
    </Button>
  );

  const SubmitCampaignButton = (
    <Button
      variant="contained"
      component="label"
      disabled={loading}
      sx={{
        paddingX: 9,
        paddingY: 2,
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: 'medium'
      }}
      onClick={handleSubmit}
    >
      Submit Your Campaign!
    </Button>
  );

  const ProgressIndicator = (
    <CircularProgress
      size={50}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px',
      }}
    />
  );

  const DefaultPlusSummary = (
    <Grid container spacing={2} columns={12} style={{minWidth: "980px"}}>
      <Grid item md={6} style={{display: "flex", flexDirection: "row-reverse", paddingRight: "20px"}}>
        <Preview
          type={contentState.type}
          illus={contentState.illus}
          color={contentState.color}
          title={contentState.title}
          tagline={contentState.tagline}
          address={contentState.address}
          showAddress={contentState.showAddress}
          hours={contentState.hours}
          showHours={contentState.showHours}
          contactInfo={contentState.contactInfo}
          showContact={contentState.showContact}
          contactIcon={contentState.contactIcon}
          userLogo={null}
          showUserLogo={false}
          ref={printRef}
        />
      </Grid>
      <Grid item md={6} style={{display: 'flex', flexDirection: 'column', justifyContent: "space-between"}}>
        <StartDate date={contentState.startDate}/>
        <Grid item style={{display: 'flex', justifyContent: "space-between"}}>
          <Grid>
            {BackButton}
          </Grid>
          <Grid>
            {SubmitCampaignButton}
            {loading && ProgressIndicator}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <div className={"page-container"}>
      <Box sx={{width: 1000, margin: "5px"}}>
        <LoadingBar step={2} totalSteps={2}/>
        <LinkLocalPageTitle title={"Review Your LinkLocal Creative"}/>
        {!showContract && DefaultPlusSummary}
        {showContract && <BasicContract setShowContract={setShowContract}/>}
        <ErrorDialog open={showErrorDialog} setOpen={setShowErrorDialog} />
      </Box>
    </div>
  )
};

export default BasicSummary;
