import React from 'react';

import '../styles/comingSoon.css';
import '../styles/submitted.css';

const Submitted = () => {
  return (
    <div className={"coming-soon"}>
      <div className={"submitted-bar"}/>
      <h2>Submitted</h2>
      <p>
        Return to our
        <a href="/">home page.</a>
      </p>
    </div>
  )
};

export default Submitted;
