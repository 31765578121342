import React from 'react';
import {TextField} from "@mui/material";

interface UserInfoProps {
  setUserName: (value: string) => void,
  userName: string,
  setUserEmail: (value: string) => void,
  userEmail: string,
}

const UserInfo = (props: UserInfoProps) => {
  const { userName, setUserName, userEmail, setUserEmail } = props;
  const userNameField = (
    <TextField
      margin={"dense"}
      label="Name"
      variant="filled"
      fullWidth
      value={userName}
      onChange={(e) => setUserName(e.target.value)}
    />);

  const userEmailField = (
    <TextField
      margin={"dense"}
      label="email@blank.com"
      variant="filled"
      fullWidth
      value={userEmail}
      onChange={(e) => setUserEmail(e.target.value)}
    />);

  return (
    <>
      {userNameField}
      {userEmailField}
    </>
  )
}

export default UserInfo;
