import {TypeOption} from "../components/adDetails/ContentType";
import {illusAssets} from "../assets/illustration";
import {ContentColor} from "../styles/colorCode";
import {createSlice} from "@reduxjs/toolkit";
import {DateTime} from "luxon";

export interface BasicContentState {
  name: string,
  email: string,
  zipCode: string,
  type: keyof typeof TypeOption,
  illus: keyof typeof illusAssets,
  color: ContentColor,
  title: string,
  tagline: string,
  address: string,
  hours: string,
  contactInfo: string,
  contactIcon: string,
  showAddress: boolean,
  showHours: boolean,
  showContact: boolean,
  startDate: number,
  preview: string,
}

const initStartDate = () => {
  return DateTime.now().setZone('America/New_York').plus({ days: 7 }).toMillis();
}

const initialState: BasicContentState = {
  name: '',
  email: '',
  zipCode: '',
  type: 'Local Business',
  illus: 'Arts',
  color: 'Blue',
  title: '',
  tagline: '',
  address: '',
  hours: '',
  contactInfo: '',
  contactIcon: 'website',
  showAddress: true,
  showHours: true,
  showContact: true,
  startDate: initStartDate(),
  preview: '',
};

const basicContentSlice = createSlice({
  name: 'basicContent',
  initialState,
  reducers: {
    storeContents(state, action) {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.zipCode = action.payload.zipCode;
      state.type = action.payload.type;
      state.illus = action.payload.illus;
      state.color = action.payload.color;
      state.title = action.payload.title;
      state.tagline = action.payload.tagline;
      state.address = action.payload.address;
      state.hours = action.payload.hours;
      state.contactInfo = action.payload.contactInfo;
      state.contactIcon = action.payload.contactIcon;
      state.showAddress = action.payload.showAddress;
      state.showHours = action.payload.showHours;
      state.showContact = action.payload.showContact;
    },
    storePreview(state, action) {
      state.preview = action.payload;
    },
    storeStartDate(state, action) {
      state.startDate = action.payload.startDate;
    }
  }
});

export const basicContentActions = basicContentSlice.actions;

export default basicContentSlice;
