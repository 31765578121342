import React from 'react';
import {Autocomplete, TextField} from '@mui/material';
import {illusAssets} from "../../assets/illustration";

interface ContentTypeProps {
  setIllus: (value: keyof typeof illusAssets) => void,
  disabled: boolean,
  value: any
}

export const ContentIllus = ({setIllus, disabled, value}: ContentTypeProps) => {
  const availableIllus = [
    'Arts',
    'Bar',
    'BID',
    'Cosmetology',
    'Event',
    'Film & Theatre',
    'Fitness',
    'Museum',
    'Music',
    'Nature',
    'Neighborhood',
    'Non-Profit',
    'Pets',
    'Pharmacy',
    'Restaurant',
    'Small Business',
    'Tech & Online',
    'Sports',
    'Neutral',
  ];

  const options = availableIllus.map(t => ({
    label: t,
    id: t.replace('&', '').replace(/\s+/g, '-').toLowerCase()
  }));

  const onChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    setIllus(value.label)
  };

  return (
    <div>
      <Autocomplete
        disabled={disabled}
        sx={{paddingBottom: 2}}
        id="user-input-content-illus"
        disableClearable
        options={options}
        defaultValue={options[0]}
        value={value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(event: React.SyntheticEvent<Element, Event>, value: any) => onChange(event, value)}
        renderInput={(params) => <TextField {...params} label="Illustration" required />}
      />
    </div>
  );
};
