import {ContentColor} from "../../styles/colorCode";

export interface AssetIllustration {
  Blue: any,
  CoolGray: any,
  Gold: any,
  Green: any,
  Orange: any,
  Pink: any,
  Purple: any,
  Red: any,
  Teal: any,
  WarmGray: any,
  DeepBlue?: any,
}

// Arts illustration
export const Arts: AssetIllustration = {
  Blue: require('./Arts/Blue.png'),
  CoolGray: require('./Arts/Cool Gray.png'),
  Gold: require('./Arts/Gold.png'),
  Green: require('./Arts/Green.png'),
  Orange: require('./Arts/Orange.png'),
  Pink: require('./Arts/Pink.png'),
  Purple: require('./Arts/Purple.png'),
  Red: require('./Arts/Red.png'),
  Teal: require('./Arts/Teal.png'),
  WarmGray: require('./Arts/Warm Gray.png'),
}

// Bar illustration
export const Bar: AssetIllustration = {
  Blue: require('./Bar/Blue.png'),
  CoolGray: require('./Bar/Cool Gray.png'),
  Gold: require('./Bar/Gold.png'),
  Green: require('./Bar/Green.png'),
  Orange: require('./Bar/Orange.png'),
  Pink: require('./Bar/Pink.png'),
  Purple: require('./Bar/Purple.png'),
  Red: require('./Bar/Red.png'),
  Teal: require('./Bar/Teal.png'),
  WarmGray: require('./Bar/Warm Gray.png'),
}

// BID illustration
export const BID: AssetIllustration = {
  Blue: require('./BID/Blue.png'),
  CoolGray: require('./BID/Cool Gray.png'),
  Gold: require('./BID/Gold.png'),
  Green: require('./BID/Green.png'),
  Orange: require('./BID/Orange.png'),
  Pink: require('./BID/Pink.png'),
  Purple: require('./BID/Purple.png'),
  Red: require('./BID/Red.png'),
  Teal: require('./BID/Teal.png'),
  WarmGray: require('./BID/Warm Gray.png'),
}

// Cosmetology illustration
export const Cosmetology: AssetIllustration = {
  Blue: require('./Cosmetology/Blue.png'),
  CoolGray: require('./Cosmetology/Cool Gray.png'),
  Gold: require('./Cosmetology/Gold.png'),
  Green: require('./Cosmetology/Green.png'),
  Orange: require('./Cosmetology/Orange.png'),
  Pink: require('./Cosmetology/Pink.png'),
  Purple: require('./Cosmetology/Purple.png'),
  Red: require('./Cosmetology/Red.png'),
  Teal: require('./Cosmetology/Teal.png'),
  WarmGray: require('./Cosmetology/Warm Gray.png'),
}

// Event illustration
export const Event: AssetIllustration = {
  Blue: require('./Event/Blue.png'),
  CoolGray: require('./Event/Cool Gray.png'),
  Gold: require('./Event/Gold.png'),
  Green: require('./Event/Green.png'),
  Orange: require('./Event/Orange.png'),
  Pink: require('./Event/Pink.png'),
  Purple: require('./Event/Purple.png'),
  Red: require('./Event/Red.png'),
  Teal: require('./Event/Teal.png'),
  WarmGray: require('./Event/Warm Gray.png'),
}

// FilmTheater illustration
export const FilmTheater: AssetIllustration = {
  Blue: require('./Film & Theater/Blue.png'),
  CoolGray: require('./Film & Theater/Cool Gray.png'),
  Gold: require('./Film & Theater/Gold.png'),
  Green: require('./Film & Theater/Green.png'),
  Orange: require('./Film & Theater/Orange.png'),
  Pink: require('./Film & Theater/Pink.png'),
  Purple: require('./Film & Theater/Purple.png'),
  Red: require('./Film & Theater/Red.png'),
  Teal: require('./Film & Theater/Teal.png'),
  WarmGray: require('./Film & Theater/Warm Gray.png'),
}

// Fitness illustration
export const Fitness: AssetIllustration = {
  Blue: require('./Fitness/Blue.png'),
  CoolGray: require('./Fitness/Cool Gray.png'),
  Gold: require('./Fitness/Gold.png'),
  Green: require('./Fitness/Green.png'),
  Orange: require('./Fitness/Orange.png'),
  Pink: require('./Fitness/Pink.png'),
  Purple: require('./Fitness/Purple.png'),
  Red: require('./Fitness/Red.png'),
  Teal: require('./Fitness/Teal.png'),
  WarmGray: require('./Fitness/Warm Gray.png'),
}

// MuseumInstitute illustration
export const MuseumInstitute: AssetIllustration = {
  Blue: require('./Museum Institute/Blue.png'),
  CoolGray: require('./Museum Institute/Cool Gray.png'),
  Gold: require('./Museum Institute/Gold.png'),
  Green: require('./Museum Institute/Green.png'),
  Orange: require('./Museum Institute/Orange.png'),
  Pink: require('./Museum Institute/Pink.png'),
  Purple: require('./Museum Institute/Purple.png'),
  Red: require('./Museum Institute/Red.png'),
  Teal: require('./Museum Institute/Teal.png'),
  WarmGray: require('./Museum Institute/Warm Gray.png'),
}

// Music illustration
export const Music: AssetIllustration = {
  Blue: require('./Music/Blue.png'),
  CoolGray: require('./Music/Cool Gray.png'),
  Gold: require('./Music/Gold.png'),
  Green: require('./Music/Green.png'),
  Orange: require('./Music/Orange.png'),
  Pink: require('./Music/Pink.png'),
  Purple: require('./Music/Purple.png'),
  Red: require('./Music/Red.png'),
  Teal: require('./Music/Teal.png'),
  WarmGray: require('./Music/Warm Gray.png'),
}

// Nature illustration
export const Nature: AssetIllustration = {
  Blue: require('./Nature/Blue.png'),
  CoolGray: require('./Nature/Cool Gray.png'),
  Gold: require('./Nature/Gold.png'),
  Green: require('./Nature/Green.png'),
  Orange: require('./Nature/Orange.png'),
  Pink: require('./Nature/Pink.png'),
  Purple: require('./Nature/Purple.png'),
  Red: require('./Nature/Red.png'),
  Teal: require('./Nature/Teal.png'),
  WarmGray: require('./Nature/Warm Gray.png'),
}

// Neighborhood illustration
export const Neighborhood: AssetIllustration = {
  Blue: require('./Neighborhood/Blue.png'),
  CoolGray: require('./Neighborhood/Cool Gray.png'),
  Gold: require('./Neighborhood/Gold.png'),
  Green: require('./Neighborhood/Green.png'),
  Orange: require('./Neighborhood/Orange.png'),
  Pink: require('./Neighborhood/Pink.png'),
  Purple: require('./Neighborhood/Purple.png'),
  Red: require('./Neighborhood/Red.png'),
  Teal: require('./Neighborhood/Teal.png'),
  WarmGray: require('./Neighborhood/Warm Gray.png'),
}

// NonProfit illustration
export const NonProfit: AssetIllustration = {
  Blue: require('./Non-Profit/Blue.png'),
  CoolGray: require('./Non-Profit/Cool Gray.png'),
  Gold: require('./Non-Profit/Gold.png'),
  Green: require('./Non-Profit/Green.png'),
  Orange: require('./Non-Profit/Orange.png'),
  Pink: require('./Non-Profit/Pink.png'),
  Purple: require('./Non-Profit/Purple.png'),
  Red: require('./Non-Profit/Red.png'),
  Teal: require('./Non-Profit/Teal.png'),
  WarmGray: require('./Non-Profit/Warm Gray.png'),
}

// Pets illustration
export const Pets: AssetIllustration = {
  Blue: require('./Pets/Blue.png'),
  CoolGray: require('./Pets/Cool Gray.png'),
  Gold: require('./Pets/Gold.png'),
  Green: require('./Pets/Green.png'),
  Orange: require('./Pets/Orange.png'),
  Pink: require('./Pets/Pink.png'),
  Purple: require('./Pets/Purple.png'),
  Red: require('./Pets/Red.png'),
  Teal: require('./Pets/Teal.png'),
  WarmGray: require('./Pets/Warm Gray.png'),
}

// PharmacyMedicine illustration
export const PharmacyMedicine: AssetIllustration = {
  Blue: require('./Pharmacy & Medicine/Blue.png'),
  CoolGray: require('./Pharmacy & Medicine/Cool Gray.png'),
  Gold: require('./Pharmacy & Medicine/Gold.png'),
  Green: require('./Pharmacy & Medicine/Green.png'),
  Orange: require('./Pharmacy & Medicine/Orange.png'),
  Pink: require('./Pharmacy & Medicine/Pink.png'),
  Purple: require('./Pharmacy & Medicine/Purple.png'),
  Red: require('./Pharmacy & Medicine/Red.png'),
  Teal: require('./Pharmacy & Medicine/Teal.png'),
  WarmGray: require('./Pharmacy & Medicine/Warm Gray.png'),
}

// Restaurant illustration
export const Restaurant: AssetIllustration = {
  Blue: require('./Restaurant/Blue.png'),
  CoolGray: require('./Restaurant/Cool Gray.png'),
  Gold: require('./Restaurant/Gold.png'),
  Green: require('./Restaurant/Green.png'),
  Orange: require('./Restaurant/Orange.png'),
  Pink: require('./Restaurant/Pink.png'),
  Purple: require('./Restaurant/Purple.png'),
  Red: require('./Restaurant/Red.png'),
  Teal: require('./Restaurant/Teal.png'),
  WarmGray: require('./Restaurant/Warm Gray.png'),
}

// SmallBusiness illustration
export const SmallBusiness: AssetIllustration = {
  Blue: require('./Small Business/Blue.png'),
  CoolGray: require('./Small Business/Cool Gray.png'),
  Gold: require('./Small Business/Gold.png'),
  Green: require('./Small Business/Green.png'),
  Orange: require('./Small Business/Orange.png'),
  Pink: require('./Small Business/Pink.png'),
  Purple: require('./Small Business/Purple.png'),
  Red: require('./Small Business/Red.png'),
  Teal: require('./Small Business/Teal.png'),
  WarmGray: require('./Small Business/Warm Gray.png'),
}

export const TechOnline: AssetIllustration = {
  Blue: require('./Tech & Online/Blue.png'),
  CoolGray: require('./Tech & Online/Cool Gray.png'),
  Gold: require('./Tech & Online/Gold.png'),
  Green: require('./Tech & Online/Green.png'),
  Orange: require('./Tech & Online/Orange.png'),
  Pink: require('./Tech & Online/Pink.png'),
  Purple: require('./Tech & Online/Purple.png'),
  Red: require('./Tech & Online/Red.png'),
  Teal: require('./Tech & Online/Teal.png'),
  WarmGray: require('./Tech & Online/Warm Gray.png'),
}

export const Sports: AssetIllustration = {
  Blue: require('./Sports/Blue.png'),
  CoolGray: require('./Sports/Cool Gray.png'),
  Gold: require('./Sports/Gold.png'),
  Green: require('./Sports/Green.png'),
  Orange: require('./Sports/Orange.png'),
  Pink: require('./Sports/Pink.png'),
  Purple: require('./Sports/Purple.png'),
  Red: require('./Sports/Red.png'),
  Teal: require('./Sports/Teal.png'),
  WarmGray: require('./Sports/Warm Gray.png'),
}

export const Library: AssetIllustration = {
  Blue: require('./Library/Deep Blue.png'),
  DeepBlue: require('./Library/Deep Blue.png'),
  CoolGray: require('./Library/Deep Blue.png'),
  Gold: require('./Library/Deep Blue.png'),
  Green: require('./Library/Deep Blue.png'),
  Orange: require('./Library/Deep Blue.png'),
  Pink: require('./Library/Deep Blue.png'),
  Purple: require('./Library/Deep Blue.png'),
  Red: require('./Library/Deep Blue.png'),
  Teal: require('./Library/Deep Blue.png'),
  WarmGray: require('./Library/Deep Blue.png'),
}

export const illusAssets = {
  'Arts': Arts,
  'Bar': Bar,
  'BID': BID,
  'Cosmetology': Cosmetology,
  'Event': Event,
  'Film & Theatre': FilmTheater,
  'Fitness': Fitness,
  'Library': Library,
  'Museum': MuseumInstitute,
  'Music': Music,
  'Nature': Nature,
  'Neighborhood': Neighborhood,
  'Non-Profit': NonProfit,
  'Pets': Pets,
  'Pharmacy': PharmacyMedicine,
  'Restaurant': Restaurant,
  'Small Business': SmallBusiness,
  'Tech & Online': TechOnline,
  'Sports': Sports,
};

export interface IllusToColor {
  [key: string]: ContentColor;
}

export const mapIllusToColor: IllusToColor = {
  'Arts': 'Teal',
  'Bar': 'Pink',
  'BID': 'Green',
  'Cosmetology': 'Purple',
  'Event': 'Blue',
  'Film & Theatre': 'Orange',
  'Fitness': 'Red',
  'Library': 'DeepBlue',
  'Museum': 'WarmGray',
  'Music': 'Teal',
  'Nature': 'Green',
  'Neighborhood': 'Blue',
  'Non-Profit': 'CoolGray',
  'Pets': 'Purple',
  'Pharmacy': 'Gold',
  'Restaurant': 'Pink',
  'Small Business': 'WarmGray',
  'Tech & Online': 'Orange',
  'Sports': 'Orange',
  'Neutral': 'CoolGray',
};
