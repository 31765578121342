import {ContentColor} from "../../styles/colorCode";
import {iconAssets} from "../../assets/Icon";
import React from "react";

interface ContactProps {
  show: boolean,
  info: string,
  color: ContentColor,
  icon: string,
}

export const Contact = (props: ContactProps) => {
  const {show, info, color, icon} = props;

  const contactInfoIcon = (contactType: string) => {
    let key: keyof typeof iconAssets = 'Web';

    if (contactType === "instagram") { key = 'Instagram'; }
    else if (contactType === "phone") { key = 'Phone'; }

    return iconAssets[key] ? iconAssets[key][color as ContentColor] : null;
  };

  return show
    ? (
      <div className={"contact"}>
        <div className={"optional-icon"}>
          <img src={contactInfoIcon(icon)} alt={icon}/>
        </div>
        <div className={"text-no-overflow"}>
          {info}
        </div>
      </div>
    )
    : null;
};
