import React, {useState} from 'react';
import {Link} from "react-router-dom";

import '../styles/readyToBegin.css'
import {ProgramType} from "../GlobalTypes";

interface ReadyToBeginProps {
  programType: ProgramType
}

const ReadyToBegin = (props: ReadyToBeginProps) => {
  const {programType} = props;
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const handlePopup = () => setShowPopup(!showPopup);

  const EligibilityRequirements = () => {
    return (
      <div className="eligibility-popup-content">
        <div className="eligibility-popup-close" onClick={handlePopup}>&times;</div>
        <div className="eligibility-popup-text">
          <br/><br/>
          <h5>Who is eligible for the LinkLocal Program?</h5>
          <ul>
            <li><p>NYC small business with less than 50 employees</p></li>
            <li><p>NYC small business with less than 3 brick and mortar locations</p></li>
            <li><p>Small online businesses based in NYC</p></li>
            <li><p>Non-profit organizations based in NYC</p></li>
            <li><p>Community organizations</p></li>
            <li><p>Schools, summer camps and museums</p></li>
          </ul>
          <h5>
            LinkLocal businesses, events and services<br/>
            <u>must</u> be located in New York City.</h5>
        </div>
      </div>
    );
  }

  const DefaultReadyToBegin = () => {
    const contentCreatorRoute = programType === ProgramType.Basic
      ? "/basic-content-creator"
      : "/plus-content-creator";

    return (
      <>
        <h3 className={"first-section"}>
          Ready to begin your LinkLocal<br/>or LinkLocal Plus application?
        </h3>
        <p className={"second-section"}>
          Please review our
          <button onClick={handlePopup}><span>eligibility requirements</span></button>
          before continuing.
        </p>
        <div className={"third-section"}>
          <p className={"first"}>
            Is your business located within one of the 5 boroughs?
          </p>
          <p className={"second"}>
            (Bronx, Brooklyn, Manhattan, Queens, Staten Island)
          </p>

          <div className={"rtb-button contact-email"}>
            <a href="/local-information.html">No</a>
            <Link to={contentCreatorRoute}>Yes</Link>
          </div>
        </div>
      </>
    )
  }
  const showEligibility = showPopup ? <EligibilityRequirements/> : <DefaultReadyToBegin/>;

  return (
    <div className={"rtb-main"}>
      {showEligibility}
    </div>
  )
};

export default ReadyToBegin;
