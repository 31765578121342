import {configureStore} from "@reduxjs/toolkit";

import plusContentSlice from "./plus-content-slice";
import plusMapSlice from "./plus-map-slice";
import plusSummarySlice from "./plus-summary";
import basicContentSlice from "./basic-content-slice";

const store = configureStore({
  reducer: {
    plusContent: plusContentSlice.reducer,
    plusMap: plusMapSlice.reducer,
    plusSummary: plusSummarySlice.reducer,
    basicContent: basicContentSlice.reducer,
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

export default store;
