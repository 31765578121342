export type ContentColor = 'Blue' | 'DeepBlue' | 'CoolGray' | 'Gold' | 'Green' | 'Orange' | 'Pink' | 'Purple' | 'Red' | 'Teal' | 'WarmGray';
export const ContentColors = {
  'Blue': {
    selectable: true,
  },
  'DeepBlue': {
    selectable: false,
  },
  'CoolGray': {
    selectable: true,
  },
  'Gold': {
    selectable: true,
  },
  'Green': {
    selectable: true,
  },
  'Orange': {
    selectable: true,
  },
  'Pink': {
    selectable: true,
  },
  'Purple': {
    selectable: true,
  },
  'Red': {
    selectable: true,
  },
  'Teal': {
    selectable: true,
  },
  'WarmGray': {
    selectable: true,
  },
};

export interface ColorCode {
  BackgroundColor1: string,
  BackgroundColor2: string,
  HeaderColor: string,
  BodyTitleColor: string,
  BodyTaglineColor: string,
  FooterTextColor: string,
}

export const Blue: ColorCode = {
  BackgroundColor1: "#0D3BEB",
  BackgroundColor2: "#DCE4FF",
  HeaderColor: "#DCE4FF",
  BodyTitleColor: "#0D3BEB",
  BodyTaglineColor: "#0D278A",
  FooterTextColor: "#0D3BEB",
};

export const DeepBlue: ColorCode = {
  BackgroundColor1: "#3C5DAA",
  BackgroundColor2: "#DDE4FF",
  HeaderColor: "#DDE4FF",
  BodyTitleColor: "#112657",
  BodyTaglineColor: "#3C5DAA",
  FooterTextColor: "#3C5DAA",
};

export const CoolGray: ColorCode = {
  BackgroundColor1: "#75868F",
  BackgroundColor2: "#E8E9E9",
  HeaderColor: "#E8E9E9",
  BodyTitleColor: "#75868F",
  BodyTaglineColor: "#424A4F",
  FooterTextColor: "#75868F",
};

export const Gold: ColorCode = {
  BackgroundColor1: "#978900",
  BackgroundColor2: "#F3EEC0",
  HeaderColor: "#F3EEC0",
  BodyTitleColor: "#978900",
  BodyTaglineColor: "#665B00",
  FooterTextColor: "#978900",
};

export const Green: ColorCode = {
  BackgroundColor1: "#009D00",
  BackgroundColor2: "#ADF9B1",
  HeaderColor: "#ADF9B1",
  BodyTitleColor: "#009D00",
  BodyTaglineColor: "#0F5D07",
  FooterTextColor: "#009D00",
};

export const Orange: ColorCode = {
  BackgroundColor1: "#EA6A00",
  BackgroundColor2: "#FFF7E9",
  HeaderColor: "#FFF7E9",
  BodyTitleColor: "#EA6A00",
  BodyTaglineColor: "#B75D00",
  FooterTextColor: "#EA6A00",
};

export const Pink: ColorCode = {
  BackgroundColor1: "#FF136B",
  BackgroundColor2: "#FDD8E7",
  HeaderColor: "#FDD8E7",
  BodyTitleColor: "#FF136B",
  BodyTaglineColor: "#9E0043",
  FooterTextColor: "#FF136B"
};

export const Purple: ColorCode = {
  BackgroundColor1: "#8C24D0",
  BackgroundColor2: "#E4D7F8",
  HeaderColor: "#E4D7F8",
  BodyTitleColor: "#8C24D0",
  BodyTaglineColor: "#42116E",
  FooterTextColor: "#8C24D0"
};
export const Red: ColorCode = {
  BackgroundColor1: "#F60A02",
  BackgroundColor2: "#FED6D1",
  HeaderColor: "#FED6D1",
  BodyTitleColor: "#F60A02",
  BodyTaglineColor: "#941500",
  FooterTextColor: "#F60A02"
};
export const Teal: ColorCode = {
  BackgroundColor1: "#018B81",
  BackgroundColor2: "#C4EBE8",
  HeaderColor: "#C4EBE8",
  BodyTitleColor: "#018B81",
  BodyTaglineColor: "#004B47",
  FooterTextColor: "#018B81"
};

export const WarmGray: ColorCode = {
  BackgroundColor1: "#8C7E74",
  BackgroundColor2: "#E9E1DB",
  HeaderColor: "#E9E1DB",
  BodyTitleColor: "#8C7E74",
  BodyTaglineColor: "#403934",
  FooterTextColor: "#8C7E74"
};

export const colorCodes = {
  "Blue": Blue,
  "DeepBlue": DeepBlue,
  "CoolGray": CoolGray,
  "Gold": Gold,
  "Green": Green,
  "Orange": Orange,
  "Pink": Pink,
  "Purple": Purple,
  "Red": Red,
  "Teal": Teal,
  "WarmGray": WarmGray,
}
