import React from 'react';
import {Autocomplete, TextField} from '@mui/material';
import {ContentColor, ContentColors} from '../../styles/colorCode';

interface ColorPickerProps {
  setColor: (value: ContentColor) => void,
  disabled: boolean,
  value: any,
}

const ColorPicker = ({setColor, disabled, value}: ColorPickerProps) => {
  const availableColor = Object.entries(ContentColors)
      .filter(([key, val]) => val.selectable)
      .map(([key, val]) => ({label: key, id: key}));
  const autocompleteOnChange = (event: React.SyntheticEvent<Element, Event>, value: any) => setColor(value.id);

  return (
    <Autocomplete
      disabled={disabled}
      sx={{paddingBottom: 2}}
      id="user-input-color"
      disableClearable
      options={availableColor}
      value={value}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event: React.SyntheticEvent<Element, Event>, value: any) => autocompleteOnChange(event, value)}
      renderInput={(params) => <TextField {...params} label="Color" required/>}
    />
  );

};

export default ColorPicker;
