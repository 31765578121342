import React from "react";
import html2canvas from "html2canvas";
import { ContentColor } from "../styles/colorCode";
import {TypeOption} from "../components/adDetails/ContentType";
import {illusAssets} from "../assets/illustration";

export interface ImageProperty {
  userLogo: string | null,
  color: ContentColor,
  "type": keyof typeof TypeOption,
  "illus": keyof typeof illusAssets,
  "title": string,
  "tagline": string,
  "address": string,
  "showAddress": boolean,
  "hours": string,
  "showHours": boolean,
  "contactInfo": string,
  "showContact": boolean,
  "contactIcon": string,
  "showUserLogo": boolean,
  "cloudLogoId": string | null,

}

export const getBase64StringFromDataURL = (dataURL: string) =>
  dataURL.replace('data:', '').replace(/^.+,/, '');

export const setUpImage = async (imgName: string,
                                 printRef: React.RefObject<HTMLDivElement>,
                                 properties: ImageProperty) => {
  const element = printRef.current;
  if (element) {
    const defaultFileName = 'myContent';
    const canvas = await html2canvas(element, {scale: 3});
    const dataURL = canvas.toDataURL('image/jpeg');
    const fileName = imgName ? imgName : defaultFileName;
    // Convert to Base64 string
    const base64Image = getBase64StringFromDataURL(dataURL);
    return {
      "name": fileName,
      "base64Image": base64Image,
      "properties": JSON.stringify(properties)
    }
  }
}
