import React, {useEffect, useState} from 'react';
import {Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";

type TotalCostProps = {
  totalKiosk: number,
  price: number,
  setPrice: (val: number) => void,
}

const TotalCost = (props: TotalCostProps) => {
  const {totalKiosk, price, setPrice} = props;
  const [tier, setTier] = useState<number>(0);

  useEffect(() => {
    switch (totalKiosk) {
      case 1:
      case 2:
        setPrice(100);
        setTier(1);
        break;
      case 3:
      case 4:
        setPrice(150);
        setTier(2);
        break;
      case 5:
      case 6:
        setPrice(200);
        setTier(3);
        break;
      case 7:
      case 8:
        setPrice(250);
        setTier(4);
        break;
      case 9:
      case 10:
        setPrice(300);
        setTier(5);
        break;
    }
  }, [totalKiosk, setPrice, setTier])

  return (
    <Box>
      <Grid item className="map-title-color">
        Total Cost
      </Grid>
      <Grid item >
        {price ?
            <Box style={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography fontSize={25}>
                Tier {tier}: <span style={{color: '#2576d2'}}> {totalKiosk} {totalKiosk === 1 ? 'kiosk' : 'kiosks'}</span>
              </Typography>
              <Typography  fontSize={25} color='#2576d2' >
                ${price}
              </Typography>
            </Box>
            :
          <Typography  fontSize={25}> - </Typography>
          }
      </Grid>
    </Box>
  )
}

export default TotalCost;
