import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TypeOption} from "../components/adDetails/ContentType";
import {illusAssets} from "../assets/illustration";
import {ContentColor} from "../styles/colorCode";

export interface PlusContentState {
  name: string,
  email: string,
  type: keyof typeof TypeOption,
  illus: keyof typeof illusAssets,
  color: ContentColor,
  title: string,
  tagline: string,
  address: string,
  hours: string,
  contactInfo: string,
  contactIcon: string,
  showAddress: boolean,
  showHours: boolean,
  showContact: boolean,
  userLogo: string | null,
  showUserLogo: boolean,
}

const initialState: PlusContentState = {
  name: '',
  email: '',
  type: 'Local Business',
  illus: 'Arts',
  color: 'Blue',
  title: '',
  tagline: '',
  address: '',
  hours: '',
  contactInfo: '',
  contactIcon: 'website',
  showAddress: true,
  showHours: true,
  showContact: true,
  userLogo: null,
  showUserLogo: true,
}

const plusContentSlice = createSlice({
  name: 'plusContent',
  initialState,
  reducers: {
    storeContents(state, action: PayloadAction<PlusContentState>) {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.type = action.payload.type;
      state.illus = action.payload.illus;
      state.color = action.payload.color;
      state.title = action.payload.title;
      state.tagline = action.payload.tagline;
      state.address = action.payload.address;
      state.hours = action.payload.hours;
      state.contactInfo = action.payload.contactInfo;
      state.contactIcon = action.payload.contactIcon;
      state.showAddress = action.payload.showAddress;
      state.showHours = action.payload.showHours;
      state.showContact = action.payload.showContact;
      state.userLogo = action.payload.userLogo;
      state.showUserLogo = action.payload.showUserLogo;
    }
  }
});

export const plusContentActions = plusContentSlice.actions;

export default plusContentSlice;
