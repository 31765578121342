import {useState, ChangeEvent} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from '@mui/material';
import { Spinner } from './Spinner';

// @ts-ignore
export const UploadFromLocalMachineDialog = ({open, handleClose, uploadStateFromChild})=> {
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState("")
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingError, setLoadingError] = useState<boolean>(false)

  const convertUploadProjectFromLocalMachine = async () =>{
    setLoading(true)
    const base64 = await convertBase64(file) as string
    const url : string = (process.env.REACT_APP_LAMBDA_URL as string)+'/getexifdatafromproject'
    convertProject(getBase64StringFromDataURL(base64), url)
      .then(res => {
        setLoading(false)
        closeUploadFromMachineDialog()
        uploadStateFromChild({'properties': JSON.parse(res.properties)})
      })
      .catch(()=> {
        setLoadingError(true)
      })
  }

  const getBase64StringFromDataURL = (dataURL: string) =>
    dataURL.replace('data:', '').replace(/^.+,/, '');

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  async function convertProject(data: any, url: string) {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data)
    });
    return await response.json();
  }

  const handleSetFile = (event: ChangeEvent<HTMLInputElement> | null) =>{
    const files = event?.target.files
    if(files){
      setFileName(files[0].name)
      setFile(files[0])
    }
  }

  const closeUploadFromMachineDialog = () => {
    handleClose()
    setFile(null)
    setFileName("")
    setTimeout(()=>{
      setLoading(false)
      setLoadingError(false)
    }, 500)
  }


  return (
    <Dialog open={open}>
      {loading ?
        <DialogContent>
          <Spinner spinnerText={"Uploading your Project"} loadingError={loadingError} loadingErrorMessage={"Sorry, your Project can't be uploaded to LinkLocal."} closeSpinner={closeUploadFromMachineDialog}/>
        </DialogContent>
        :
        <>
          <DialogTitle>Upload from local machine</DialogTitle>
          <DialogContent>
            <Grid   container direction="column" alignItems="center" >
              {fileName &&
                <Typography  sx={{m: 1}} variant="subtitle1" component="div">
                    Selected Project: {fileName}
                </Typography>
              }
              <Button variant="contained" component="label">
                Select Project
                <input hidden accept="image/*" type="file" onChange={(event) => handleSetFile(event)}/>
              </Button>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeUploadFromMachineDialog}>Cancel</Button>
            <Button disabled={!fileName} onClick={convertUploadProjectFromLocalMachine}>UPLOAD PROJECT</Button>
          </DialogActions>
        </>
      }
    </Dialog>
  );
};
