import {ContentColor} from "../../styles/colorCode";
import {iconAssets} from "../../assets/Icon";
import React from "react";

interface HoursProps {
  showHours: boolean,
  showContact: boolean,
  hours: string,
  color: ContentColor,
}

export const Hours = (props: HoursProps) => {
  const {showHours, showContact, hours, color} = props;
  const iconSrc = iconAssets['Date'] ? iconAssets['Date'][color as ContentColor] : null;
  return showHours
    ? (
      <div className={"hours"}>
        <div className={"optional-icon"}>
          <img src={iconSrc} alt={"Hour"}/>
        </div>
        <div className={"wrapper-text"}>
          <div className={"text-overflow optional-up-to-2-lines"}>
            {hours}
          </div>
          { showContact ?
            <div className={"dashed_bottom"}>
              ...................................................................................................
            </div>
            :
            null
          }
        </div>
      </div>
    )
    : null;
};
