import {useRouteError} from "react-router-dom";

import '../styles/errorPage.css'

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page" className={"not-found"}>
      <div className={"horizontal-line"}/>
      <h2>Sorry, we couldn't find the page you're looking for.</h2>
      <p>
        Return to our
        <a href="/">home page.</a>
      </p>
    </div>
  );
}
