import Box from "@mui/material/Box";

type LinkLocalPageTitleProps = {
  title: string
}

const LinkLocalPageTitle = ({title}: LinkLocalPageTitleProps) => {

  return (
    <Box sx={{padding:'40px 0 10px 0'}}>
        <div style={{width: '83px', height: '7px', background: 'black'}}/>
        <h4>{title}</h4>
    </Box>
  );
};

export default LinkLocalPageTitle;
