import {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from '@mui/material';
import { Spinner } from './Spinner';

interface UploadDialogProps {
  open: boolean,
  handleClose: () => void,
  handleContinue: (fileName: string) => void,
  spinner: boolean
  spinnerText: string
  text: JSX.Element
  title: string
  continueButton: string
  loadingError: boolean
}

export const ProjectDialog = ({open, handleClose, handleContinue, spinner, text, title, continueButton, spinnerText, loadingError}: UploadDialogProps) => {
  const [fileName, setFileName] = useState<string>('');

  return (
    <Dialog open={open} onClose={handleClose}>
      {spinner ?
        <DialogContent>
          <Spinner spinnerText={spinnerText} loadingError={loadingError} closeSpinner={handleClose}/>
        </DialogContent>
        :
          <>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {text}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="content-file-name"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => setFileName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={() => handleContinue(fileName)}>{continueButton}</Button>
          </DialogActions>
        </>
      }
    </Dialog>
  );
};
