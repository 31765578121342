import React from "react";
import xLogo from "../../assets/img/Social_x.png";
import facebookLogo from "../../assets/img/Social_facebook.png";
import instagramLogo from "../../assets/img/Social_instagram.png";
import rightArrow from "../../assets/img/right-arrow.svg";
import '../../styles/pressReleases.css';

const Sidebar = () => (
    <div className="sidebar">
        <div className="sidebar-header">
            <hr className="divider"/>
            <span className="link" style={{color: '#05519a'}}>
                <div>
                    <a href="/I1qRlWdvvBN2Zg5AkS74dhHHqQgI3iiULpVasUCmsLl4EAt7YZ/news" className="no-decoration">
                        Press Releases
                    </a>
                </div>
                <div><img src={rightArrow} alt={"LinkNYC News"} style={{width: '17px'}}/></div>

            </span>
            <hr className="divider"/>
            <span className="link" style={{color: '#686868'}}>
                <div>Reports</div>
                <div><img src={rightArrow} alt={"LinkNYC News"} style={{width: '17px'}}/></div>
            </span>
            <hr className="divider"/>
        </div>
        <div className="social-icons">
            <div className="social-icon">
                <a href="https://x.com/LinkNYC" target="_blank" rel="noopener noreferrer">
                    <img src={xLogo} alt="LinkNYC X" className="icon" style={{width: '9px'}}/>
                </a>
            </div>
            <div className="social-icon icon-middle">
                <a href="https://www.facebook.com/linknyc.freewifi/" target="_blank" rel="noopener noreferrer">
                    <img src={facebookLogo} alt="LinkNYC Facebook" className="icon"/>
                </a>
            </div>
            <div className="social-icon">
                <a href="https://www.instagram.com/linknycofficial/" target="_blank" rel="noopener noreferrer">
                    <img src={instagramLogo} alt="LinkNYC Instagram" className="icon"/>
                </a>
            </div>
        </div>
    </div>
);

export default Sidebar;
