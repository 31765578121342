import React from 'react';
import {Autocomplete, TextField} from "@mui/material";
import {ProgramType} from "../../GlobalTypes";

export const TypeOption = {
  'Local Business': {
    id: 'localBusiness',
    supported: [ProgramType.Basic, ProgramType.Plus, ProgramType.Internal],
  },
  'Local Event': {
    id: 'localEvent',
    supported: [ProgramType.Basic, ProgramType.Plus, ProgramType.Internal],
  },
  'Local Non-Profit': {
    id: 'localNonProfit',
    supported: [ProgramType.Basic, ProgramType.Plus, ProgramType.Internal],
  },
  'Local Library': {
    id: 'localLibrary',
    supported: [ProgramType.Internal],
  },
}

interface ContentTypeProps {
  setType: (value: keyof typeof TypeOption) => void,
  value: any,
  programType: ProgramType,
}

export const ContentType = ({setType, value, programType}: ContentTypeProps) => {

  const options = Object.entries(TypeOption)
    .filter(([, val]) => val.supported.includes(programType))
    .map(([key, val]) => ({label: key, id: val.id}));

  const onChange = (_event: React.SyntheticEvent<Element, Event>, value: any) => {
    setType(value.label)
  };

  return (
    <div>
      <Autocomplete
        sx={{paddingBottom: 2}}
        id="user-input-content-type"
        disableClearable
        options={options}
        defaultValue={options[0]}
        value={value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(event: React.SyntheticEvent<Element, Event>, value: any) => onChange(event, value)}
        renderInput={(params) => <TextField {...params} label="Type" required/>}
      />
    </div>
  );
};
