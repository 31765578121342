import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {plusContentActions, PlusContentState} from "../store/plus-content-slice";

import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Preview from "./Preview";
import {illusAssets} from '../assets/illustration';
import {ContentColor} from '../styles/colorCode';
import '../styles/content-creator.css';
import {TypeOption} from "./adDetails/ContentType";
import {Spinner} from './Spinner';
import UserInfo from "./UserInfo";
import ContentDetails from "./ContentDetails";
import LogoButtons from "./adDetails/LogoButtons";
import LoadingBar from "./LoadingBar";
import {Button, Tooltip} from "@mui/material";
import {RootState} from '../store';
import {ProgramType} from "../GlobalTypes";

const Item = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const PlusContentCreator = () => {
  const savedState: PlusContentState = useSelector((state: RootState) => state.plusContent);

  const [userName, setUserName] = useState<string>(savedState.name);
  const [userEmail, setUserEmail] = useState<string>(savedState.email);
  const [contentType, setContentType] = useState<keyof typeof TypeOption>(savedState.type);
  const [contentIllus, setContentIllus] = useState<keyof typeof illusAssets>(savedState.illus);
  const [contentColor, setContentColor] = useState<ContentColor>(savedState.color);
  const [contentTitle, setContentTitle] = useState<string>(savedState.title);
  const [contentTagline, setContentTagline] = useState<string>(savedState.tagline);
  const [contentAddress, setContentAddress] = useState<string>(savedState.address);
  const [contentHours, setContentHours] = useState<string>(savedState.hours);
  const [contactInfo, setContactInfo] = useState<string>(savedState.contactInfo);
  const [contactIcon, setContactIcon] = useState<string>(savedState.contactIcon)
  const [userLogo, setUserLogo] = useState<string | null>(savedState.userLogo);
  const [showUserLogo, setShowUserLogo] = useState<boolean>(savedState.showUserLogo);
  const [showAddress, setShowAddress] = useState<boolean>(savedState.showAddress);
  const [showHours, setShowHours] = useState<boolean>(savedState.showHours);
  const [showContact, setShowContact] = useState<boolean>(savedState.showContact);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<boolean>(false);

  const dispatch = useDispatch();
  const storeContents = () => {
    dispatch(plusContentActions.storeContents({
      name: userName,
      email: userEmail,
      type: contentType,
      illus: contentIllus,
      color: contentColor,
      title: contentTitle,
      tagline: contentTagline,
      address: contentAddress,
      hours: contentHours,
      contactInfo: contactInfo,
      contactIcon: contactIcon,
      showAddress: showAddress,
      showHours: showHours,
      showContact: showContact,
      userLogo: userLogo,
      showUserLogo: showUserLogo
    }));
  }

  const navigate = useNavigate();
  const redirectToMap = () => {
    storeContents();
    navigate("/map");
  }

  const dummyFunc = () => {};

  useEffect(() => {
    if (!userLogo) return;
    const timer = setTimeout(() => setShowUserLogo(true), 500);
    return () => clearTimeout(timer);
  }, [userLogo]);

  const closeSpinnerAndContinueAfterError = () => {
    setLoading(false)
    setLoadingError(false)
  }

  return (
    <div className={"page-container"}>
      <Box sx={{width: 1000, margin: "5px"}}>
        {loading ?
          <div className='spinner-loading'>
            <Spinner spinnerText={'Loading Project...'}
                     loadingError={loadingError}
                     loadingErrorMessage={"There is an issue with logo, please upload it manually!"}
                     closeSpinner={closeSpinnerAndContinueAfterError}/>
          </div>
          :
          <>
            <LoadingBar step={1} totalSteps={3}/>
            <Grid container spacing={2} columns={12}>
              <Grid item xs={7}>
                <Item>
                  <UserInfo userName={userName} setUserName={setUserName} userEmail={userEmail}
                            setUserEmail={setUserEmail}/>
                </Item>
                <Item>
                  <ContentDetails
                    programType={ProgramType.Plus}
                    contentType={contentType}
                    contentIllus={contentIllus}
                    disableIllus={false}
                    contentColor={contentColor}
                    disableColor={false}
                    title={contentTitle}
                    tagline={contentTagline}
                    setType={setContentType}
                    setIllus={setContentIllus}
                    setColor={setContentColor}
                    setTitle={setContentTitle}
                    setTagline={setContentTagline}
                    showAddress={showAddress}
                    setShowAddress={setShowAddress}
                    showHours={showHours}
                    setShowHours={setShowHours}
                    showContact={showContact}
                    setShowContact={setShowContact}
                    address={contentAddress}
                    setAddress={setContentAddress}
                    hours={contentHours}
                    setHours={setContentHours}
                    contactIcon={contactIcon}
                    setContactIcon={setContactIcon}
                    contactInfo={contactInfo}
                    setContactInfo={setContactInfo}/>
                  <LogoButtons
                    setUserLogo={setUserLogo}
                    setShowUserLogo={setShowUserLogo}
                    setCloudLogoId={dummyFunc}/>
                </Item>
              </Grid>
              <Grid item xs={5}>
                <Item>
                  <Preview
                    type={contentType}
                    illus={contentIllus}
                    color={contentColor}
                    title={contentTitle}
                    tagline={contentTagline}
                    address={contentAddress}
                    showAddress={showAddress}
                    hours={contentHours}
                    showHours={showHours}
                    contactInfo={contactInfo}
                    showContact={showContact}
                    contactIcon={contactIcon}
                    userLogo={userLogo}
                    showUserLogo={showUserLogo}
                    ref={null}
                  />
                </Item>
                <div className={"continue-button"}>
                  <Tooltip title="Continue to select your kiosk" arrow>
                    <Button variant="contained"
                            component="label"
                            size="large"
                            sx={{
                              paddingX: 10,
                              paddingY: 1,
                              textTransform: "none",
                              fontSize: "large"
                            }}
                            onClick={redirectToMap}
                    >
                      Continue
                    </Button>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </>}
      </Box>
    </div>
  );
}

export default PlusContentCreator;
