import React, {ChangeEvent} from 'react';
import {Button, Tooltip} from "@mui/material";

interface LogoButtonsProps {
  setUserLogo: any
  setShowUserLogo: any
  setCloudLogoId: any
}

const LogoButtons = (props: LogoButtonsProps) => {
  const { setUserLogo, setShowUserLogo, setCloudLogoId } = props;

  const handleFileRead = async (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const base64 = await convertBase64(file) as string
    setUserLogo(base64)
    setCloudLogoId(null)
  }

  const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const onClickDeleteLogo = () => {
    setShowUserLogo(false);
    setUserLogo(null);
    setCloudLogoId(null);
  };

  return (
    <div className={"align-left"}>
      <Tooltip title="Upload Logo to Project" arrow>
        <Button variant="contained" component="label">
          Upload Logo
          <input hidden accept="image/*" type="file" onChange={(e) => handleFileRead(e)}/>
        </Button>
      </Tooltip>
      <Tooltip title="Delete Logo from Project" arrow>
        <Button sx={{marginLeft: "20px"}} variant="contained" component="label" onClick={onClickDeleteLogo}>
          Delete Logo
        </Button>
      </Tooltip>
    </div>
  );

};

export default LogoButtons;
