import {createSlice} from "@reduxjs/toolkit";
import {MapMarkers} from "../components/LinkMap";
import {DateTime} from "luxon";

export interface PlusMapState {
  selectedMarkers: MapMarkers,
  startDate: number,
  price: number
}

const initStartDate = () => {
  return DateTime.now().setZone('America/New_York').plus({ days: 7 }).toMillis();
}

const initialState: PlusMapState = {
  selectedMarkers: {},
  price: 0,
  startDate: initStartDate()
}

const plusMapSlice = createSlice({
  name: 'plusMap',
  initialState,
  reducers: {
    storeMapData(state, action) {
      state.selectedMarkers = action.payload.selectedMarkers;
      state.price = action.payload.price;
    },
    storeStartDate(state, action) {
      state.startDate = action.payload.startDate;
    }
  }
});

export const plusMapActions = plusMapSlice.actions;

export default plusMapSlice;
