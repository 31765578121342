import {useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';
import ProjectTable from './ProjectTable'
import {Spinner} from './Spinner'
import { TypeOption } from './adDetails/ContentType';
import { illusAssets } from '../assets/illustration';
import { ContentColor } from '../styles/colorCode';


interface OpenFromGoogleCloudDialogInterface {
    open: boolean,
    handleClose: () => void
    uploadStateFromChild: (data: Data | null) => void
}


export interface Data {
  id: string,
  modifiedTime: string,
  name: string,
  properties: {
    address: string,
    cloudLogoId: string,
    color: ContentColor,
    contactIcon: string,
    contactInfo: string,
    hours: string,
    illus: keyof typeof illusAssets,
    showAddress: string,
    showContact: string,
    showHours: string,
    tagline: string,
    title: string,
    type: keyof typeof TypeOption,
    userLogo: string,
    showUserLogo: boolean,
  }
}


export const OpenFromGoogleCloudDialog = ({open, handleClose, uploadStateFromChild}: OpenFromGoogleCloudDialogInterface) => {
    const [projectMetadata, setProjectMetadata] = useState<[]>([]);
    const [search, setSearch] = useState('');
    const [selectedRow, setSelectedRow] = useState<Data | null>(null);
    const [loadingError, setLoadingError] = useState<boolean>(false);

    useEffect(() => {
        if (open){
            const fetchData = async () => {
                await getContentList()
                  .then(data => setProjectMetadata(data))
                  .catch(() => setLoadingError(true))
            }
            fetchData()
        }

    }, [open])

    async function getContentList() {
      const url : string = (process.env.REACT_APP_LAMBDA_URL as string)
      const response = await fetch(url, {
        method: "GET",
      });
      return  await response.json();
    }

    const handleOnClose = () => {
        handleClose()
        setSearch('')
        setSelectedRow(null)
    }

    const getSelectedRow = (data: Data) => {
      setSelectedRow(data)
    }


    const uploadStateFromChildAndClose = (data: Data) => {
        uploadStateFromChild(data)
        handleOnClose()
    }

    const handlerOnOpen = () => {
      uploadStateFromChild(selectedRow)
      handleOnClose()
    }

  return (
        <Dialog
          open={open} onClose={handleOnClose} sx={{
            "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "1000px",  // Set your width here
                },
            },
        }}>
        <DialogTitle>Select Project From Google Cloud</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
            <TextField id="standard-basic" label="Search Project Name" variant="standard" onChange={(event)=>setSearch(event.target.value)}/>
        </DialogContent>
          {projectMetadata.length ?
            <>
            <DialogContent>
              <ProjectTable projectMetadata={projectMetadata.filter((img: any) => img.name.toLowerCase().includes(search.toLowerCase()))}
                            uploadStateFromChildAndClose={uploadStateFromChildAndClose}
                            getSelectedRow={getSelectedRow}
                            selectedRow={selectedRow}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleOnClose}>Cancel</Button>
              <Button disabled={!selectedRow } onClick={handlerOnOpen}>Open</Button>
            </DialogActions>
            </>
           :
            <DialogContent>
              <Spinner spinnerText={"Loading Google Cloud Projects..."} loadingError={loadingError} closeSpinner={handleClose}/>
            </DialogContent>
          }
        </Dialog>
    );
};
