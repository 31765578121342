import {ContentColor} from '../../styles/colorCode';
import {Address} from './Address';
import {Hours} from './Hours';
import {Contact} from './Contact';

interface OptionalProps {
  showAddress: boolean,
  showHours: boolean,
  showContact: boolean,
  color: ContentColor,
  address: string,
  hours: string,
  contactInfo: string,
  contactIcon: string,
}

export const Optional = (props: OptionalProps) => {
  const {showAddress, showHours, showContact, color, address, hours, contactInfo, contactIcon} = props;
  const showOptional = (showAddress || showHours || showContact)
  return showOptional
    ?
    (
      <div className={"optional"}>
        <Address showAddress={showAddress} showHours={showHours} showContact={showContact} address={address}
                 color={color}/>
        <Hours showHours={showHours} showContact={showContact} hours={hours} color={color}/>
        <Contact show={showContact} info={contactInfo} icon={contactIcon} color={color}/>
      </div>
    )
    : null;
}
