import {useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableHead } from '@mui/material';
import {Data} from './OpenFromGoogleCloudDialog'

interface ProjectTableProps{
  projectMetadata: Data[],
  uploadStateFromChildAndClose: (data: Data)=> void,
  getSelectedRow: (data: Data)=> void,
  selectedRow: Data | null,
}

export default function ProjectTable({projectMetadata, uploadStateFromChildAndClose, getSelectedRow, selectedRow}: ProjectTableProps) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = projectMetadata.length > rowsPerPage ? page > 0 ? Math.max(0, (1 + page) * rowsPerPage - projectMetadata.length) : 0 : rowsPerPage - projectMetadata.length

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlerLoadProject = (data: Data) => {
    uploadStateFromChildAndClose(data)
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <TableCell align={'left'}>Name</TableCell>
            <TableCell align={'left'} >Title</TableCell>
            <TableCell align={'left'}>Time Uploaded</TableCell>
            <TableCell align={'left'}>Business Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
              ? projectMetadata.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : projectMetadata
          ).map((row: Data) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
              onDoubleClick={() => handlerLoadProject(row)}
              onClick={() => getSelectedRow(row)}
              selected={selectedRow !== undefined && row.name === selectedRow?.name}
            >
              <TableCell component="th" scope="row">{row.name.substr(0, row.name.lastIndexOf("_"))}</TableCell>
              <TableCell align="left">{row.properties.title}</TableCell>
              <TableCell align="left">{new Date(row.modifiedTime).toLocaleDateString()} - {new Date(row.modifiedTime).toLocaleTimeString()}</TableCell>
              <TableCell align="left">{row.properties.type}</TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={4}
              count={projectMetadata.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
