import React, {useEffect, useRef, useState} from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Preview from "./Preview";
import {illusAssets} from '../assets/illustration';
import {ContentColor} from '../styles/colorCode';
import '../styles/content-creator.css';
import {TypeOption} from "./adDetails/ContentType";
import {Spinner} from './Spinner';
import {Data} from './OpenFromGoogleCloudDialog'
import ContentDetails from "./ContentDetails";
import LogoButtons from "./adDetails/LogoButtons";
import ContentLoadButtons from "./ContentLoadButtons";
import {getBase64StringFromDataURL, ImageProperty, setUpImage} from "../util/image";
import {ProgramType} from "../GlobalTypes";

const Item = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const InternalContentCreator = () => {
  const [contentType, setContentType] = useState<keyof typeof TypeOption>('Local Business');
  const [contentIllus, setContentIllus] = useState<keyof typeof illusAssets>('Arts');
  const [disableIllus, setDisableIllus] = useState<boolean>(false);
  const [contentColor, setContentColor] = useState<ContentColor>('Blue');
  const [disableColor, setDisableColor] = useState<boolean>(false);
  const [contentTitle, setContentTitle] = useState<string>('');
  const [contentTagline, setContentTagline] = useState<string>('');
  const [contentAddress, setContentAddress] = useState<string>('');
  const [contentHours, setContentHours] = useState<string>('');
  const [contactInfo, setContactInfo] = useState<string>('');
  const [contactIcon, setContactIcon] = useState<string>('website')
  const [userLogo, setUserLogo] = useState<string | null>(null);
  const [showUserLogo, setShowUserLogo] = useState<boolean>(true);
  const [showAddress, setShowAddress] = useState<boolean>(true);
  const [showHours, setShowHours] = useState<boolean>(true);
  const [showContact, setShowContact] = useState<boolean>(true);
  const [cloudLogoId, setCloudLogoId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<boolean>(false);

  const printRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!userLogo) return;
    const timer = setTimeout(() => setShowUserLogo(true), 500);
    return () => clearTimeout(timer);
  }, [userLogo]);

  useEffect(() => {
    if (contentType === 'Local Library') {
      setContentIllus('Library');
      setContentColor('DeepBlue');
      setDisableIllus(true);
      setDisableColor(true);
    } else {
      setContentIllus('Arts');
      setContentColor('Blue');
      setDisableIllus(false);
      setDisableColor(false);
    }
  }, [contentType])

  async function getCloudLogo(logo: string) {
    const url : string = (process.env.REACT_APP_LAMBDA_URL as string)
    const response = await fetch(`${url}/logo?id=${logo}`, {
      method: "GET",
    });
    return await response.json();
  }

  const uploadStateFromChild = (data: Data | null) => {
    const { properties } = data as Data
    setLoading(true)
    setContentType(properties.type)
    setContentIllus(properties.illus)
    setContentColor(properties.color)
    setContentTitle(properties.title)
    setContentTagline(properties.tagline)
    setContentAddress(properties.address)
    setContentHours(properties.hours)
    setContactInfo(properties.contactInfo)
    setContactIcon(properties.contactIcon)
    setShowUserLogo(!!(properties.showUserLogo && properties.userLogo))
    setShowAddress(JSON.parse(properties.showAddress))
    setShowHours(JSON.parse(properties.showHours))
    setShowContact(JSON.parse(properties.showContact))
    setCloudLogoId(properties.userLogo)
    if (properties.userLogo){
      getCloudLogo(properties.userLogo).then((filename) => {
        setUserLogo("data:image/jpeg;base64," + filename)
        setLoading(false)
      }).catch(()=> setLoadingError(true))
    }
    else{
      setUserLogo(null)
      setLoading(false)
    }
  }

  const closeSpinnerAndContinueAfterError = () =>{
    setLoading(false)
    setLoadingError(false)
  }

  const createImageMetaData = async (imgName: string) => {
    const properties: ImageProperty = {
      userLogo: showUserLogo && userLogo ? getBase64StringFromDataURL(userLogo) : null,
      color: contentColor,
      type: contentType,
      illus: contentIllus,
      title: contentTitle,
      tagline: contentTagline,
      address: contentAddress,
      showAddress: showAddress,
      hours: contentHours,
      showHours: showHours,
      contactInfo: contactInfo,
      showContact: showContact,
      contactIcon: contactIcon,
      showUserLogo: showUserLogo,
      cloudLogoId: cloudLogoId,

    };
    return setUpImage(imgName, printRef, properties);
  }

  return (
    <div className={"page-container"}>
      <Box sx={{width: 1000, margin: "5px"}}>
        {loading ?
          <div className='spinner-loading'>
            <Spinner spinnerText={'Loading Project...'}
                     loadingError={loadingError}
                     loadingErrorMessage={"There is an issue with logo, please upload it manually!"}
                     closeSpinner={closeSpinnerAndContinueAfterError}/>
          </div>
          :
          <Grid container spacing={2} columns={12}>
            <Grid item xs={7}>
              <Item>
                <ContentDetails
                  programType={ProgramType.Internal}
                  contentType={contentType}
                  contentIllus={contentIllus}
                  disableIllus={disableIllus}
                  contentColor={contentColor}
                  disableColor={disableColor}
                  title={contentTitle}
                  tagline={contentTagline}
                  setType={setContentType}
                  setIllus={setContentIllus}
                  setColor={setContentColor}
                  setTitle={setContentTitle}
                  setTagline={setContentTagline}
                  showAddress={showAddress}
                  setShowAddress={setShowAddress}
                  showHours={showHours}
                  setShowHours={setShowHours}
                  showContact={showContact}
                  setShowContact={setShowContact}
                  address={contentAddress}
                  setAddress={setContentAddress}
                  hours={contentHours}
                  setHours={setContentHours}
                  contactIcon={contactIcon}
                  setContactIcon={setContactIcon}
                  contactInfo={contactInfo}
                  setContactInfo={setContactInfo}/>
                <LogoButtons
                  setUserLogo={setUserLogo}
                  setShowUserLogo={setShowUserLogo}
                  setCloudLogoId={setCloudLogoId}/>
              </Item>
            </Grid>
            <Grid item xs={5}>
              <Item>
                <Preview
                  type={contentType}
                  illus={contentIllus}
                  color={contentColor}
                  title={contentTitle}
                  tagline={contentTagline}
                  address={contentAddress}
                  showAddress={showAddress}
                  hours={contentHours}
                  showHours={showHours}
                  contactInfo={contactInfo}
                  showContact={showContact}
                  contactIcon={contactIcon}
                  userLogo={userLogo}
                  showUserLogo={showUserLogo}
                  ref={printRef}
                />
                <ContentLoadButtons setCloudLogoId={setCloudLogoId}
                                    uploadStateFromChild={uploadStateFromChild}
                                    createImageMetaData={createImageMetaData}/>
              </Item>
            </Grid>
          </Grid>}
      </Box>
    </div>
  );
}

export default InternalContentCreator;
