import '../styles/loadingBar.css'

type LoadingBarProps = {
  step: number,
  totalSteps: number,
}

const LoadingBar = ({step, totalSteps}: LoadingBarProps) => {

  const loadingBarStyle = `loading-bar-graph-fill ${totalSteps === 3 ? "three-bars" : "two-bars"}`
  const stepTwoColor = step >= 2 ? '#2576d2' : '#f2f2f2';
  const stepThreeColor = step === 3 ? '#2576d2' : '#f2f2f2';

  return (
    <div className="loading-bar-container">
      <div className="loading-bar-wrapper">
        <span>Step {step} (of {totalSteps})</span>
        <div className="loading-bar-graph">
          <div className={loadingBarStyle} style={{backgroundColor: '#2576d2'}}/>
          <div className={loadingBarStyle} style={{backgroundColor: stepTwoColor}}/>
          {totalSteps === 3 ? <div className={loadingBarStyle} style={{backgroundColor: stepThreeColor}}/> : null}
        </div>
      </div>
    </div>
  );
};

export default LoadingBar;
