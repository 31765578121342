import { useEffect } from 'react';

const Redirect = ( props : {url: string} ) => {
  useEffect(() => {
    window.location.href = props.url as string;
  }, [props.url]);
  return null;
}

export default Redirect;
