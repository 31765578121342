import React, {useState} from 'react';
import {Data, OpenFromGoogleCloudDialog} from "./OpenFromGoogleCloudDialog";
import {ProjectDialog} from "./ProjectDialog";
import {UploadFromLocalMachineDialog} from "./UploadFromLocalMachineDialog";
import {Button, Tooltip} from "@mui/material";

interface ResponseBodyInterface {
  name: string,
  base64Image: string,
  properties: string
}

interface ContentLoadButtonsProps {
  setCloudLogoId: (logoID: string) => void,
  uploadStateFromChild: (data: Data | null) => void,
  createImageMetaData: (imgName: string) => Promise<{ base64Image: string, name: string, properties: string } | undefined>,
}

const ContentLoadButtons = (props: ContentLoadButtonsProps) => {
  const {setCloudLogoId, uploadStateFromChild, createImageMetaData} = props;

  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [openUploadFromGoogleCloudDialog, setOpenUploadFromGoogleCloudDialog] = useState(false);
  const [openUploadFromLocalMachineDialog, setOpenUploadFromLocalMachineDialog] = useState<boolean>(false);
  const [spinner, setSpinner] = useState(false);
  const [loadingError, setLoadingError] = useState<boolean>(false);

  const handleUploadImage = async (uploadFileName: string) => {
    setSpinner(true)
    const url: string = (process.env.REACT_APP_LAMBDA_URL as string)

    createImageMetaData(uploadFileName)
      .then(body => postContentImage(body, url))
      .then((res) => {
        // use logoID from already uploaded logo from handleDownloadImage
        if (res.logoid) {
          setCloudLogoId(res.logoid)
        }
        setOpenUploadDialog(false);
        setTimeout(() => setSpinner(false), 500);
      }).catch(() => setLoadingError(true))

  }

  const closeDownloadDialog = () => {
    setOpenSaveDialog(false)
    setTimeout(() => {
      setSpinner(false)
      setLoadingError(false)
    }, 500)
  };

  const closeUploadDialog = () => {
    setOpenUploadDialog(false)
    setTimeout(() => {
      setSpinner(false)
      setLoadingError(false)
    }, 500)
  };

  const closeUploadFromLocalMachineDialog = () => {
    setOpenUploadFromLocalMachineDialog(false)
  };

  const closeGoogleCloudDialog = () => {
    setOpenUploadFromGoogleCloudDialog(false)
    setLoadingError(false)
  }

  const handleDownloadImage = async (downloadFileName: string) => {
    setSpinner(true)
    const url: string = (process.env.REACT_APP_LAMBDA_URL as string) + '/download'
    createImageMetaData(downloadFileName)
      .then(body => postContentImage(body, url))
      .then(res => {
        // use logoID from already uploaded logo from handleUploadImage
        if (res.logoid) {
          setCloudLogoId(res.logoid)
        }
        var a = document.createElement("a")
        a.href = `data:image/jpeg;base64,${res.filename.substring(2).slice(0, -1)}`
        a.download = `${downloadFileName}.jpeg`;
        a.click();
        setOpenSaveDialog(false);
        setTimeout(() => setSpinner(false), 500);
      }).catch(() => setLoadingError(true));
  };

  async function postContentImage(data: ResponseBodyInterface | undefined, url: string) {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data)
    });
    return await response.json();
  }

  const downloadButton = (
    <div className={"preview-button"}>
      <Tooltip title="Open Project from Google Cloud" arrow>
        <Button variant="contained" component="label" onClick={() => setOpenUploadFromGoogleCloudDialog(true)}>
          OPEN
        </Button>
      </Tooltip>

      <Tooltip title="Save Project to Google Cloud" arrow>
        <Button variant="contained" component="label" onClick={() => setOpenUploadDialog(true)}>
          SAVE
        </Button>
      </Tooltip>
      <Tooltip title="Upload Project from local machine" arrow>
        <Button variant="contained" component="label"
                onClick={() => setOpenUploadFromLocalMachineDialog(true)}>Upload</Button>
      </Tooltip>
      <Tooltip title="Download Project to local machine" arrow>
        <Button variant="contained" component="label" onClick={() => setOpenSaveDialog(true)}>
          Download
        </Button>
      </Tooltip>
    </div>
  );

  const saveText = (
    <>
      Enter the filename for the created image.<br/>
      The image will be uploaded to the content storage of Link.NYC.
    </>
  )

  const downloadText = (
    <>
      Enter the filename for the created Project.<br/>
      The Project will be saved as jpeg.
    </>
  )

  return (
    <>
      {downloadButton}
      <OpenFromGoogleCloudDialog
        open={openUploadFromGoogleCloudDialog}
        handleClose={closeGoogleCloudDialog}
        uploadStateFromChild={uploadStateFromChild}
      />
      <ProjectDialog
        title={"Save LinkLocal Project"}
        text={saveText}
        continueButton={"Save"}
        spinner={spinner}
        spinnerText={"Saving Project..."}
        loadingError={loadingError}
        open={openUploadDialog}
        handleClose={closeUploadDialog}
        handleContinue={handleUploadImage}
      />
      <ProjectDialog
        title={"Download LinkLocal Project"}
        text={downloadText}
        continueButton={"Download"}
        spinner={spinner}
        loadingError={loadingError}
        spinnerText={"Downloading Project..."}
        open={openSaveDialog}
        handleClose={closeDownloadDialog}
        handleContinue={handleDownloadImage}
      />
      <UploadFromLocalMachineDialog
        open={openUploadFromLocalMachineDialog}
        handleClose={closeUploadFromLocalMachineDialog}
        uploadStateFromChild={uploadStateFromChild}
      />
    </>
  )
};

export default ContentLoadButtons;
