import {AssetIllustration} from "../illustration";

export const Date: AssetIllustration = {
  Blue: require('./Date/Blue.png'),
  DeepBlue: require('./Date/Deep Blue.png'),
  CoolGray: require('./Date/Cool Gray.png'),
  Gold: require('./Date/Gold.png'),
  Green: require('./Date/Green.png'),
  Orange: require('./Date/Orange.png'),
  Pink: require('./Date/Pink.png'),
  Purple: require('./Date/Purple.png'),
  Red: require('./Date/Red.png'),
  Teal: require('./Date/Teal.png'),
  WarmGray: require('./Date/Warm Gray.png'),
};

export const Location: AssetIllustration = {
  Blue: require('./Location/Blue.png'),
  DeepBlue: require('./Location/Deep Blue.png'),
  CoolGray: require('./Location/Cool Gray.png'),
  Gold: require('./Location/Gold.png'),
  Green: require('./Location/Green.png'),
  Orange: require('./Location/Orange.png'),
  Pink: require('./Location/Pink.png'),
  Purple: require('./Location/Purple.png'),
  Red: require('./Location/Red.png'),
  Teal: require('./Location/Teal.png'),
  WarmGray: require('./Location/Warm Gray.png'),
};

export const Web: AssetIllustration = {
  Blue: require('./Web/Blue.png'),
  DeepBlue: require('./Web/Deep Blue.png'),
  CoolGray: require('./Web/Cool Gray.png'),
  Gold: require('./Web/Gold.png'),
  Green: require('./Web/Green.png'),
  Orange: require('./Web/Orange.png'),
  Pink: require('./Web/Pink.png'),
  Purple: require('./Web/Purple.png'),
  Red: require('./Web/Red.png'),
  Teal: require('./Web/Teal.png'),
  WarmGray: require('./Web/Warm Gray.png'),
};

export const Instagram: AssetIllustration = {
  Blue: require('./Instagram/Blue.png'),
  DeepBlue: require('./Instagram/Deep Blue.png'),
  CoolGray: require('./Instagram/Cool Gray.png'),
  Gold: require('./Instagram/Gold.png'),
  Green: require('./Instagram/Green.png'),
  Orange: require('./Instagram/Orange.png'),
  Pink: require('./Instagram/Pink.png'),
  Purple: require('./Instagram/Purple.png'),
  Red: require('./Instagram/Red.png'),
  Teal: require('./Instagram/Teal.png'),
  WarmGray: require('./Instagram/Warm Gray.png'),
};

export const Phone: AssetIllustration = {
  Blue: require('./Phone/Blue.png'),
  DeepBlue: require('./Phone/Deep Blue.png'),
  CoolGray: require('./Phone/Cool Gray.png'),
  Gold: require('./Phone/Gold.png'),
  Green: require('./Phone/Green.png'),
  Orange: require('./Phone/Orange.png'),
  Pink: require('./Phone/Pink.png'),
  Purple: require('./Phone/Purple.png'),
  Red: require('./Phone/Red.png'),
  Teal: require('./Phone/Teal.png'),
  WarmGray: require('./Phone/Warm Gray.png'),
};

export const iconAssets = {
  'Date': Date,
  'Location': Location,
  'Web': Web,
  'Instagram': Instagram,
  'Phone': Phone,
};
