import React from 'react';

import '../styles/comingSoon.css';

const ComingSoon = () => {
  return (
    <div className={"coming-soon"}>
      <div className={"horizontal-line"}/>
      <h2>Coming Soon</h2>
      <p>
        Return to our
        <a href="/">home page.</a>
      </p>
    </div>
  )
};

export default ComingSoon;
