import {useEffect} from 'react';

const NewsAdmin = () => {
    useEffect(() => {
        window.location.href = 'http://100.20.96.139/wp-login.php';
    }, []);
    return null;
}

export default NewsAdmin;
