import React from 'react';
import {WordPressPost} from "./interfaces";
import Sidebar from "./Sidebar";
import '../../styles/pressReleases.css';

interface NewsProps {
    posts: WordPressPost[]
}

const News = (props: NewsProps) => {
    const { posts } = props;

    const postList = (
        <div className='news-post-list'>
            <h3>
                <div></div>
            </h3>
            {posts
                .sort((a: WordPressPost, b: WordPressPost) => {
                    const dateA = new Date(a.post_date);
                    const dateB = new Date(b.post_date);
                    return dateA.getTime() - dateB.getTime();
                })
                .map((post: WordPressPost, index) => (
                    <div className='news-post' key={index}>
                        <a
                            rel="noopener noreferrer"
                            href={`/I1qRlWdvvBN2Zg5AkS74dhHHqQgI3iiULpVasUCmsLl4EAt7YZ/${post.ID}`}
                        >
                            <div className='news-post-title'>{post.title}</div>
                        </a>

                        <span className='news-post-date'>{post.post_date}</span>
                        <hr/>
                    </div>
                ))}
        </div>
    );

    return (
        <div className='top-div'>
            <section className='news-section-1'>
                <div className='news-section-div'>
                    <h1>
                        <div style={{backgroundColor: 'white'}}></div>
                        Press Releases
                    </h1>
                </div>
            </section>
            <section className='news-section-2'>
                <Sidebar/>
                {postList}
            </section>
        </div>
    );
}

export default News;
