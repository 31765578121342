import {DateTime} from "luxon";

export const getCurrentTimeInSeconds = async () => {
  const url: string = (process.env.REACT_APP_LAMBDA_URL as string) + '/current-timestamp'
  const response = await fetch(url);
  const data = await response.json();
  return data['timestamp'];
}

export const formatToISODate = (timestamp: number): string => {
  const dateTimeInNY = DateTime.fromMillis(timestamp).setZone('America/New_York');
  return dateTimeInNY.toISODate() as string;
}
